<template>
  <v-main>
    <v-container v-if="projectData">
      <h1>Invoices for {{ projectData.projectName }}</h1>

      <v-data-table
        :headers="invoiceHeaders"
        :items="invoiceItems"
        class="elevation-1"
      >
        <template v-slot:[`item.month`]="{ item }">
          {{ item.month }}
        </template>
        <template v-slot:[`item.invoicePdf`]="{ item }">
          <v-btn
            v-if="item.invoicePdfUrl"
            :href="item.invoicePdfUrl"
            target="_blank"
            color="primary"
            text
          >
            Download PDF
          </v-btn>
          <span v-else>No PDF invoice</span>
        </template>
        <template v-slot:[`item.invoiceXml`]="{ item }">
          <v-btn
            v-if="item.invoiceXmlUrl"
            :href="item.invoiceXmlUrl"
            target="_blank"
            color="primary"
            text
          >
            Download E-Invoice
          </v-btn>
          <span v-else>No E-invoice</span>
        </template>
        <!-- Actions Column Slot -->
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            :loading="item.isLoading" 
            color="primary"
            text
            @click="triggerInvoiceGeneration(item)"
          >
            Generate Invoice
          </v-btn>
        </template>
      </v-data-table>

      <!-- Success Message -->
      <v-snackbar v-model="showSuccessSnackbar" color="success" timeout="5000">
        {{ successMessage }}
      </v-snackbar>

      <!-- Error Message -->
      <v-snackbar v-model="showErrorSnackbar" color="error" timeout="5000">
        {{ errorMessage }}
      </v-snackbar>
    </v-container>

    <!-- Loading Indicator when projectData is not loaded yet -->
    <v-container v-else>
      <v-row justify="center" align="center" style="height: 300px;">
        <v-progress-circular indeterminate color="primary" size="64"></v-progress-circular>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import { collection, getDocs, doc, getDoc } from 'firebase/firestore'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { db } from '@/firebaseConfig'

export default {
  name: 'ProjectInvoicesPage',
  data() {
    return {
      projectId: '',
      projectData: null,
      invoiceHeaders: [
        { text: 'Month', value: 'month' },
        { text: 'Invoice PDF', value: 'invoicePdf' },
        { text: 'Invoice XML', value: 'invoiceXml' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      invoiceItems: [],
      successMessage: '',
      showSuccessSnackbar: false,
      errorMessage: '',
      showErrorSnackbar: false,
    }
  },
  async created() {
    const auth = getAuth()
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        this.projectId = this.$route.params.id
        await this.fetchProjectData(user.uid)
        await this.fetchInvoices(user.uid)
      } else {
        this.$router.push('/login')
      }
    })
  },
  methods: {
    async fetchProjectData(userId) {
      try {
        const projectRef = doc(db, 'USERS', userId, 'projects', this.projectId)
        const projectSnap = await getDoc(projectRef)
        if (projectSnap.exists()) {
          this.projectData = projectSnap.data()
        } else {
          this.$router.push('/dashboard')
        }
      } catch (error) {
        console.error('Error fetching project:', error)
        this.$router.push('/dashboard')
      }
    },
    async fetchInvoices(userId) {
      try {
        const timesheetsRef = collection(
          db,
          'USERS',
          userId,
          'projects',
          this.projectId,
          'timesheets'
        )
        const timesheetsSnapshot = await getDocs(timesheetsRef)

        this.invoiceItems = timesheetsSnapshot.docs.map((doc) => {
          const data = doc.data()
          const monthId = doc.id
          const year = monthId.substring(0, 4)
          const monthNumber = monthId.substring(4, 6)
          const monthName = new Date(
            year,
            parseInt(monthNumber) - 1
          ).toLocaleString('default', { month: 'long' })
          return {
            month: `${monthName} ${year}`,
            invoicePdfUrl: data.invoicePdfUrl || null,
            invoiceXmlUrl: data.invoiceXmlUrl || null,
            isLoading: false, // Add this line
          }
        })

        // Sort the items by month in descending order
        this.invoiceItems.sort((a, b) => b.month.localeCompare(a.month))
      } catch (error) {
        console.error('Error fetching invoices:', error)
      }
    },
    async triggerInvoiceGeneration(item) {
      item.isLoading = true

      try {
        const auth = getAuth()
        const user = auth.currentUser

        if (!user) {
          this.$router.push('/login')
          item.isLoading = false
          return
        }

        const functions = getFunctions()

        const generateInvoice = httpsCallable(functions, 'generateInvoiceOnDemand')

        // Parse month and year from the item.month (e.g., "August 2023")
        const [monthName, year] = item.month.split(' ')
        const monthNumber = new Date(`${monthName} 1, ${year}`).getMonth() + 1

        const result = await generateInvoice({
          projectId: this.projectId,
          month: monthNumber,
          year: parseInt(year, 10),
        })

        console.log('Invoice generation successful:', result.data)
        this.successMessage = 'Invoice generated and sent successfully.'
        this.showSuccessSnackbar = true

        await this.fetchInvoices(user.uid)
      } catch (error) {
        console.error('Error generating invoice:', error)

        if (error.code === 'not-found') {
          this.errorMessage = 'No timesheet data found for the specified month and year.'
        } else if (error.code === 'invalid-argument') {
          this.errorMessage = 'Invalid project ID, month, or year.'
        } else {
          this.errorMessage = 'Error generating invoice. Please try again later.'
        }
        this.showErrorSnackbar = true
      } finally {
        item.isLoading = false
      }
    },
  },
}
</script>

<style scoped>
.v-container {
  max-width: 800px;
  margin: 0 auto;
}

h1 {
  text-align: center;
  margin-bottom: 20px;
}
</style>