<template>
    <v-main>
      <v-container>
        <h1>Edit Project</h1>
  
        <!-- Display Active Status -->
        <v-alert
          v-if="projectData"
          :type="projectData.active ? 'success' : 'warning'"
          class="mb-4"
          prominent
        >
          Project is currently {{ projectData.active ? 'Active' : 'Inactive' }}.
        </v-alert>
  
        <!-- Render form only after projectData is loaded -->
        <v-form
          ref="form"
          v-model="valid"
          @submit.prevent="onSubmit"
          v-if="projectData"
        >
          <!-- Save Button at Top -->
          <v-btn color="primary" type="submit" class="mb-4">
            Save Changes
          </v-btn>
  
          <!-- Project Name -->
          <v-card class="mb-6">
            <v-card-title>Project Name</v-card-title>
            <v-card-text>
              <v-text-field
                v-model="projectData.projectName"
                label="Project Name"
                :rules="projectNameRules"
                required
              ></v-text-field>
            </v-card-text>
          </v-card>
  
          <!-- Client Email -->
          <v-card class="mb-6">
            <v-card-title>Client Company Email</v-card-title>
            <v-card-text>
              <v-text-field
                v-model="projectData.clientEmail"
                label="Client Email"
                :rules="emailRules"
                required
                hint="This is the email you were given at the client's company, we will be sending timesheets to it"
                persistent-hint
              ></v-text-field>
            </v-card-text>
          </v-card>
          <!-- Intermediary Selection -->
          <v-card class="mb-6">
            <v-card-title>Do you use an intermediary company?</v-card-title>
            <v-card-text>
              <v-radio-group v-model="usesIntermediary" row>
                <v-radio label="Yes" value="yes"></v-radio>
                <v-radio label="No" value="no"></v-radio>
              </v-radio-group>
            </v-card-text>
          </v-card>
  
          <!-- Intermediary Company Information -->
          <v-card class="mb-6" v-if="usesIntermediary === 'yes'">
            <v-card-title>1. Intermediary Company Information</v-card-title>
            <v-card-text>
              <!-- VAT Number Field and Retrieve Info Button -->
              <v-row align="center">
                <v-col cols="12" sm="8">
                  <v-text-field
                    v-model="projectData.intermediaryCompany.vatNumber"
                    label="Intermediary VAT Number"
                    :rules="vatNumberRules"
                    required
                    hint="Enter the VAT number of the intermediary company"
                    persistent-hint
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-btn
                    :loading="loadingIntermediary"
                    color="primary"
                    @click="validateIntermediaryVATNumber"
                    block
                  >
                    Retrieve Info
                  </v-btn>
                </v-col>
              </v-row>
  
              <!-- Intermediary Company Name Field -->
              <v-text-field
                v-model="projectData.intermediaryCompany.companyName"
                label="Intermediary Company Name"
                :rules="companyNameRules"
                required
              ></v-text-field>
  
              <!-- Intermediary Street Address Field -->
              <v-text-field
                v-model="projectData.intermediaryCompany.streetAddress"
                label="Street Address"
                :rules="streetAddressRules"
                required
              ></v-text-field>
  
              <!-- Intermediary City Name Field -->
              <v-text-field
                v-model="projectData.intermediaryCompany.cityName"
                label="City"
                :rules="cityNameRules"
                required
              ></v-text-field>
  
              <!-- Intermediary Postal Code Field -->
              <v-text-field
                v-model="projectData.intermediaryCompany.postalCode"
                label="Postal Code"
                :rules="postalCodeRules"
                required
              ></v-text-field>
  
              <!-- Intermediary Country Field -->
              <v-select
                v-model="projectData.intermediaryCompany.country"
                :items="countryOptions"
                label="Country"
                :rules="companyCountryRules"
                item-title="text"
                item-value="value"
                required
              ></v-select>
  
              <!-- Reference Number -->
              <v-text-field
                v-model="projectData.intermediaryCompany.referenceNumber"
                label="Reference Number"
                hint="Optional reference number for the intermediary company"
                persistent-hint
              ></v-text-field>
            </v-card-text>
          </v-card>
  
          <!-- Client Company Information -->
          <v-card class="mb-6">
            <v-card-title>2. Client Company (Purchaser) Information</v-card-title>
            <v-card-text>
              <!-- Conditional VAT Number Field and Retrieve Info Button -->
              <v-row align="center" v-if="usesIntermediary === 'no'">
                <v-col cols="12" sm="8">
                  <v-text-field
                    v-model="projectData.clientCompany.vatNumber"
                    label="Client VAT Number"
                    :rules="vatNumberRules"
                    required
                    hint="Enter the VAT number of the client company"
                    persistent-hint
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-btn
                    :loading="loadingClient"
                    color="primary"
                    @click="validateClientVATNumber"
                    block
                  >
                    Retrieve Info
                  </v-btn>
                </v-col>
              </v-row>
  
              <!-- Client Company Name Field -->
              <v-text-field
                v-model="projectData.clientCompany.companyName"
                label="Client Company Name"
                :rules="companyNameRules"
                required
              ></v-text-field>
  
              <!-- Client Street Address Field -->
              <v-text-field
                v-model="projectData.clientCompany.streetAddress"
                label="Street Address"
                :rules="streetAddressRules"
                v-if="usesIntermediary === 'no'"
                required
              ></v-text-field>
  
              <!-- Client City Name Field -->
              <v-text-field
                v-model="projectData.clientCompany.cityName"
                label="City"
                :rules="cityNameRules"
                v-if="usesIntermediary === 'no'"
                required
              ></v-text-field>
  
              <!-- Client Postal Code Field -->
              <v-text-field
                v-model="projectData.clientCompany.postalCode"
                label="Postal Code"
                :rules="postalCodeRules"
                v-if="usesIntermediary === 'no'"
                required
              ></v-text-field>
  
              <!-- Client Country Field -->
              <v-select
                v-model="projectData.clientCompany.country"
                :items="countryOptions"
                label="Country"
                :rules="companyCountryRules"
                item-title="text"
                item-value="value"
                v-if="usesIntermediary === 'no'"
                required
              ></v-select>
  
              <!-- Manager Name Field -->
              <v-text-field
                v-model="projectData.clientCompany.managerName"
                label="Manager Name (optional)"
                hint="Name of your contact person at the client company"
                persistent-hint
              ></v-text-field>
            </v-card-text>
          </v-card>
  
          <!-- Project Details -->
          <v-card class="mb-6">
            <v-card-title>3. Project Details</v-card-title>
            <v-card-text>
              <!-- Project Description -->
              <v-textarea
                v-model="projectData.projectDetails.description"
                label="Project Description"
                :rules="projectDescriptionRules"
                hint="Brief description of the project (e.g., IT support)"
                persistent-hint
                required
              ></v-textarea>
  
              <!-- Rate Input -->
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    v-model.number="projectData.projectDetails.hourlyRate"
                    label="Hourly Rate"
                    type="number"
                    :rules="rateRules"
                    @input="calculateDayRate"
                    hint="Enter your hourly rate"
                    persistent-hint
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model.number="projectData.projectDetails.dayRate"
                    label="Day Rate"
                    type="number"
                    :rules="rateRules"
                    @input="calculateHourlyRate"
                    hint="Enter your daily rate"
                    persistent-hint
                  ></v-text-field>
                </v-col>
              </v-row>
  
              <!-- Hours per Work Day -->
              <v-text-field
                v-model.number="projectData.projectDetails.workDayHours"
                label="Hours per Work Day"
                type="number"
                :rules="workDayHoursRules"
                hint="Default number of working hours in a day (adjustable)"
                persistent-hint
                @input="recalculateRates"
              ></v-text-field>
  
              <!-- Calculated Rate Display -->
              <v-alert
                v-if="projectData.projectDetails.hourlyRate && projectData.projectDetails.dayRate"
                type="info"
                class="mt-4"
              >
                Based on {{ projectData.projectDetails.workDayHours }} hours per day, your rates are:
                Hourly Rate: {{ projectData.projectDetails.hourlyRate.toFixed(2) }},
                Day Rate: {{ projectData.projectDetails.dayRate.toFixed(2) }}
              </v-alert>
  
              <!-- Work Days Selection -->
              <h3>Default Work Days</h3>
              <p>Select the days you will work by default. This helps generate your default timesheet.</p>
              <v-btn-toggle v-model="projectData.projectDetails.workDays" multiple>
                <v-btn v-for="day in weekdays" :key="day.value" :value="day.value">
                  {{ day.text }}
                </v-btn>
              </v-btn-toggle>
            </v-card-text>
          </v-card>
  
          <!-- Advanced Options -->
          <v-card class="mb-6">
            <v-card-title>
              <v-btn color="primary" @click="advancedOptions = !advancedOptions">
                {{ advancedOptions ? 'Hide' : 'Show' }} Advanced Options
              </v-btn>
            </v-card-title>
            <v-card-text v-if="advancedOptions">
              <!-- Start Date -->
              <v-menu
                ref="startDateMenu"
                v-model="startDateMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template #activator="{ on, attrs }">
                  <v-text-field
                    v-model="projectData.projectDetails.startDate"
                    label="Project Start Date"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    hint="Optional start date of the project"
                    persistent-hint
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="projectData.projectDetails.startDate"
                  @input="startDateMenu = false"
                ></v-date-picker>
              </v-menu>
  
              <!-- End Date -->
              <v-menu
                ref="endDateMenu"
                v-model="endDateMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template #activator="{ on, attrs }">
                  <v-text-field
                    v-model="projectData.projectDetails.endDate"
                    label="Project End Date"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    hint="Optional end date of the project"
                    persistent-hint
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="projectData.projectDetails.endDate"
                  @input="endDateMenu = false"
                ></v-date-picker>
              </v-menu>
  
              <!-- Manager Email Field -->
              <v-text-field
                v-model="projectData.clientCompany.managerEmail"
                label="Manager Email (optional)"
                hint="Email address of your contact person at the client company"
                persistent-hint
              ></v-text-field>
  
              <!-- Invoice Send Date -->
              <v-select
                v-model="projectData.projectDetails.invoiceSendDate"
                :items="invoiceSendDateOptions"
                label="When should we send the invoice and timesheet?"
                hint="Default is last day of the month"
                persistent-hint
              ></v-select>
  
              <!-- Holiday Calendar -->
              <v-select
                v-model="projectData.projectDetails.holidayCalendar"
                :items="countryOptions"
                label="Holiday Calendar"
                item-title="text"
                item-value="value"
                hint="Country whose holidays will be considered (default is your company country)"
                persistent-hint
              ></v-select>
  
              <!-- Timesheet Mail Time -->
              <v-text-field
                v-model="projectData.projectDetails.timesheetMailTime"
                label="When the daily timesheet is sent"
                type="time"
                hint="Time when timesheet emails are sent (default is 08:00)"
                persistent-hint
              ></v-text-field>
            </v-card-text>
          </v-card>
  
        <!-- Save Button at the Bottom -->
        <v-btn color="primary" type="submit">Save Changes</v-btn>
      </v-form>

      <!-- Add spacing between the form and the action buttons -->
      <div class="my-4"></div>

      <!-- Activate/Deactivate Button -->
      <v-btn
        v-if="projectData"
        :color="projectData.active ? 'warning' : 'success'"
        @click="toggleProjectStatus"
        class="mr-4"
      >
        {{ projectData.active ? 'Deactivate' : 'Activate' }} Project
      </v-btn>

      <!-- Delete Button -->
      <v-btn v-if="projectData" color="red" @click="deleteProject">
        Delete Project
      </v-btn>

      <!-- Loading Indicator -->
      <v-progress-circular
        v-if="!projectData"
        indeterminate
        color="primary"
        class="mt-5"
      ></v-progress-circular>

      <!-- Snackbar for User Feedback -->
      <v-snackbar
        v-model="snackbar.show"
        :color="snackbar.color"
        timeout="3000"
        top
        right
      >
        {{ snackbar.message }}
        <v-btn color="white" text @click="snackbar.show = false">Close</v-btn>
      </v-snackbar>
    </v-container>
  </v-main>
</template>
  
<script>
import { getAuth } from 'firebase/auth'
import { doc, getDoc, updateDoc, deleteDoc } from 'firebase/firestore'
import { db, functions } from '@/firebaseConfig'
import { httpsCallable } from 'firebase/functions'

export default {
  name: 'ProjectDetailsPage',
  data() {
    return {
      valid: false,
      projectData: {
        id: '',
        projectName: '',
        clientEmail: '',
        usesIntermediary: false,
        intermediaryCompany: {
          vatNumber: '',
          companyName: '',
          streetAddress: '',
          cityName: '',
          postalCode: '',
          country: '',
          referenceNumber: '',
        },
        clientCompany: {
          vatNumber: '',
          companyName: '',
          streetAddress: '',
          cityName: '',
          postalCode: '',
          country: '',
          managerName: '',
          managerEmail: '',
        },
        projectDetails: {
          description: '',
          hourlyRate: null,
          dayRate: null,
          workDayHours: 8,
          workDays: [],
          startDate: '',
          endDate: '',
          invoiceSendDate: 'Last day of the month',
          holidayCalendar: '',
          timesheetMailTime: '08:00',
        },
        active: true,
      },
      usesIntermediary: 'no',
      advancedOptions: false,
      loadingIntermediary: false,
      loadingClient: false,
      startDateMenu: false,
      endDateMenu: false,
      snackbar: {
        show: false,
        message: '',
        color: 'success',
      },
      // Validation Rules
      projectNameRules: [(v) => !!v || 'Project name is required'],
      vatNumberRules: [(v) => !!v || 'VAT number is required'],
      companyNameRules: [(v) => !!v || 'Company name is required'],
      streetAddressRules: [(v) => !!v || 'Street address is required'],
      cityNameRules: [(v) => !!v || 'City name is required'],
      postalCodeRules: [(v) => !!v || 'Postal code is required'],
      companyCountryRules: [(v) => !!v || 'Please select a country'],
      projectDescriptionRules: [
        (v) => !!v || 'Project description is required',
      ],
      rateRules: [(v) => v >= 0 || 'Rate must be a positive number'],
      workDayHoursRules: [(v) => v > 0 || 'Must be greater than 0'],
      emailRules: [
        (v) => !!v || 'Email is required',
        (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      // Options
      countryOptions: [
        { text: 'Argentina', value: 'AR' },
        { text: 'Australia', value: 'AU' },
        { text: 'Austria', value: 'AT' },
        { text: 'Belgium', value: 'BE' },
        { text: 'Brazil', value: 'BR' },
        { text: 'Canada', value: 'CA' },
        { text: 'Chile', value: 'CL' },
        { text: 'China', value: 'CN' },
        { text: 'Colombia', value: 'CO' },
        { text: 'Bulgaria', value: 'BG' },
        { text: 'Croatia', value: 'HR' },
        { text: 'Cyprus', value: 'CY' },
        { text: 'Czech Republic', value: 'CZ' },
        { text: 'Denmark', value: 'DK' },
        { text: 'Egypt', value: 'EG' },
        { text: 'Estonia', value: 'EE' },
        { text: 'Finland', value: 'FI' },
        { text: 'France', value: 'FR' },
        { text: 'Germany', value: 'DE' },
        { text: 'Greece', value: 'GR' },
        { text: 'Hong Kong', value: 'HK' },
        { text: 'Hungary', value: 'HU' },
        { text: 'Iceland', value: 'IS' },
        { text: 'India', value: 'IN' },
        { text: 'Indonesia', value: 'ID' },
        { text: 'Ireland', value: 'IE' },
        { text: 'Israel', value: 'IL' },
        { text: 'Italy', value: 'IT' },
        { text: 'Japan', value: 'JP' },
        { text: 'Latvia', value: 'LV' },
        { text: 'Lithuania', value: 'LT' },
        { text: 'Luxembourg', value: 'LU' },
        { text: 'Malaysia', value: 'MY' },
        { text: 'Mexico', value: 'MX' },
        { text: 'Malta', value: 'MT' },
        { text: 'Netherlands', value: 'NL' },
        { text: 'New Zealand', value: 'NZ' },
        { text: 'Nigeria', value: 'NG' },
        { text: 'Norway', value: 'NO' },
        { text: 'Peru', value: 'PE' },
        { text: 'Philippines', value: 'PH' },
        { text: 'Poland', value: 'PL' },
        { text: 'Portugal', value: 'PT' },
        { text: 'Russia', value: 'RU' },
        { text: 'Saudi Arabia', value: 'SA' },
        { text: 'Singapore', value: 'SG' },
        { text: 'Romania', value: 'RO' },
        { text: 'Slovakia', value: 'SK' },
        { text: 'Slovenia', value: 'SI' },
        { text: 'South Africa', value: 'ZA' },
        { text: 'South Korea', value: 'KR' },
        { text: 'Spain', value: 'ES' },
        { text: 'Sweden', value: 'SE' },
        { text: 'Switzerland', value: 'CH' },
        { text: 'Taiwan', value: 'TW' },
        { text: 'Thailand', value: 'TH' },
        { text: 'Turkey', value: 'TR' },
        { text: 'United Arab Emirates', value: 'AE' },
        { text: 'United Kingdom', value: 'GB' },
        { text: 'United States', value: 'US' },
        { text: 'Venezuela', value: 'VE' },
        { text: 'Vietnam', value: 'VN' },
      ],
        invoiceSendDateOptions: ['Last day of the month', 'Custom Date'],
      weekdays: [
        { text: 'Mon', value: 'Monday' },
        { text: 'Tue', value: 'Tuesday' },
        { text: 'Wed', value: 'Wednesday' },
        { text: 'Thu', value: 'Thursday' },
        { text: 'Fri', value: 'Friday' },
        { text: 'Sat', value: 'Saturday' },
        { text: 'Sun', value: 'Sunday' },
      ],
    }
  },
  async created() {
    const auth = getAuth()
    const user = auth.currentUser

    if (!user) {
      this.$router.push('/login')
      return
    }

    const projectId = this.$route.params.id
    try {
      const projectRef = doc(db, 'USERS', user.uid, 'projects', projectId)
      const projectSnap = await getDoc(projectRef)
      if (projectSnap.exists()) {
        const data = projectSnap.data()
        this.projectData = {
          ...this.projectData, // Default values
          ...data, // Data from Firestore
          id: projectId, // Ensure the ID is set
        }
        // Ensure nested objects are merged correctly
        this.projectData.intermediaryCompany = {
          ...this.projectData.intermediaryCompany,
          ...data.intermediaryCompany,
        }
        this.projectData.clientCompany = {
          ...this.projectData.clientCompany,
          ...data.clientCompany,
        }
        this.projectData.projectDetails = {
          ...this.projectData.projectDetails,
          ...data.projectDetails,
        }
        this.usesIntermediary = this.projectData.usesIntermediary ? 'yes' : 'no'
      } else {
        this.snackbar.message = 'Project not found.'
        this.snackbar.color = 'error'
        this.snackbar.show = true
        this.$router.push('/dashboard')
      }
    } catch (error) {
      console.error('Error fetching project:', error)
      this.snackbar.message = 'Failed to fetch project.'
      this.snackbar.color = 'error'
      this.snackbar.show = true
      this.$router.push('/dashboard')
    }
  },
  methods: {
    calculateDayRate() {
      const pd = this.projectData.projectDetails
      if (pd.hourlyRate && pd.workDayHours) {
        pd.dayRate = pd.hourlyRate * pd.workDayHours
      }
    },
    calculateHourlyRate() {
      const pd = this.projectData.projectDetails
      if (pd.dayRate && pd.workDayHours) {
        pd.hourlyRate = pd.dayRate / pd.workDayHours
      }
    },
    recalculateRates() {
      const pd = this.projectData.projectDetails
      if (pd.hourlyRate) {
        this.calculateDayRate()
      } else if (pd.dayRate) {
        this.calculateHourlyRate()
      }
    },
      async validateIntermediaryVATNumber() {
        if (!this.projectData.intermediaryCompany.vatNumber) {
          this.snackbar.message = 'Please enter a VAT number.'
          this.snackbar.color = 'error'
          this.snackbar.show = true
          return
        }
        try {
          this.loadingIntermediary = true // Start loading animation
  
          // Format VAT number by removing non-alphanumeric characters
          const vatNumber = this.projectData.intermediaryCompany.vatNumber.replace(/[^a-zA-Z0-9]/g, '').toUpperCase()
  
          const validateVAT = httpsCallable(functions, 'validateVAT')
          const result = await validateVAT({ vatNumber })
          console.log('validateVAT result:', result)
  
          // Check if the VAT number is registered
          if (
            result.data.registration_info &&
            result.data.registration_info.is_registered
          ) {
            // Pre-fill company details based on API response
            this.projectData.intermediaryCompany.companyName =
              result.data.registration_info.name || this.projectData.intermediaryCompany.companyName
  
            // Split the address into components
            if (result.data.registration_info.address) {
              const address = result.data.registration_info.address
              this.parseIntermediaryAddress(address)
            }
  
            this.projectData.intermediaryCompany.country =
              result.data.country_code || this.projectData.intermediaryCompany.country
  
            this.snackbar.message =
              'VAT number is valid. Company details have been pre-filled.'
            this.snackbar.color = 'success'
            this.snackbar.show = true
          } else {
            this.snackbar.message = 'Invalid VAT number.'
            this.snackbar.color = 'error'
            this.snackbar.show = true
          }
        } catch (error) {
          console.error('VAT validation error:', error)
          this.snackbar.message = 'Failed to validate VAT number.'
          this.snackbar.color = 'error'
          this.snackbar.show = true
        } finally {
          this.loadingIntermediary = false // Stop loading animation
        }
      },
      async validateClientVATNumber() {
        if (!this.projectData.clientCompany.vatNumber) {
          this.snackbar.message = 'Please enter a VAT number.'
          this.snackbar.color = 'error'
          this.snackbar.show = true
          return
        }
        try {
          this.loadingClient = true // Start loading animation
  
          // Format VAT number by removing non-alphanumeric characters
          const vatNumber = this.projectData.clientCompany.vatNumber.replace(/[^a-zA-Z0-9]/g, '').toUpperCase()
  
          const validateVAT = httpsCallable(functions, 'validateVAT')
          const result = await validateVAT({ vatNumber })
          console.log('validateVAT result:', result)
  
          // Check if the VAT number is registered
          if (
            result.data.registration_info &&
            result.data.registration_info.is_registered
          ) {
            // Pre-fill company details based on API response
            this.projectData.clientCompany.companyName =
              result.data.registration_info.name || this.projectData.clientCompany.companyName
  
            // Split the address into components
            if (result.data.registration_info.address) {
              const address = result.data.registration_info.address
              this.parseClientAddress(address)
            }
  
            this.projectData.clientCompany.country =
              result.data.country_code || this.projectData.clientCompany.country
  
            this.snackbar.message =
              'VAT number is valid. Company details have been pre-filled.'
            this.snackbar.color = 'success'
            this.snackbar.show = true
          } else {
            this.snackbar.message = 'Invalid VAT number.'
            this.snackbar.color = 'error'
            this.snackbar.show = true
          }
        } catch (error) {
          console.error('VAT validation error:', error)
          this.snackbar.message = 'Failed to validate VAT number.'
          this.snackbar.color = 'error'
          this.snackbar.show = true
        } finally {
          this.loadingClient = false // Stop loading animation
        }
      },
      parseIntermediaryAddress(address) {
        // Assuming the address is in the format "Street Address\nPostal Code City"
        const lines = address.split('\n')
        if (lines.length >= 2) {
          this.projectData.intermediaryCompany.streetAddress = lines[0]
          const cityLine = lines[1]
          const match = cityLine.match(/^(\d{4,6})\s+(.+)/)
          if (match) {
            this.projectData.intermediaryCompany.postalCode = match[1]
            this.projectData.intermediaryCompany.cityName = match[2]
          } else {
            this.projectData.intermediaryCompany.cityName = cityLine
          }
        } else {
          this.projectData.intermediaryCompany.streetAddress = address
        }
      },
      parseClientAddress(address) {
        // Similar to parseIntermediaryAddress
        const lines = address.split('\n')
        if (lines.length >= 2) {
          this.projectData.clientCompany.streetAddress = lines[0]
          const cityLine = lines[1]
          const match = cityLine.match(/^(\d{4,6})\s+(.+)/)
          if (match) {
            this.projectData.clientCompany.postalCode = match[1]
            this.projectData.clientCompany.cityName = match[2]
          } else {
            this.projectData.clientCompany.cityName = cityLine
          }
        } else {
          this.projectData.clientCompany.streetAddress = address
        }
      },
      async onSubmit() {
        if (this.$refs.form.validate()) {
          try {
            const auth = getAuth()
            const user = auth.currentUser
  
            if (!user) {
              throw new Error('User is not authenticated')
            }
  
            const projectRef = doc(db, 'USERS', user.uid, 'projects', this.projectData.id)
            await updateDoc(projectRef, {
              // Update fields with new data
              ...this.projectData,
              usesIntermediary: this.usesIntermediary === 'yes',
            })
  
            this.snackbar.message = 'Project updated successfully!'
            this.snackbar.color = 'success'
            this.snackbar.show = true
  
            // Optionally, redirect to the dashboard
             this.$router.push('/dashboard')
          } catch (error) {
            console.error('Error updating project:', error)
            this.snackbar.message = 'Failed to update project.'
            this.snackbar.color = 'error'
            this.snackbar.show = true
          }
        }
      },
      async deleteProject() {
      // Confirmation dialog
      const confirmed = confirm('Are you sure you want to delete this project? This action cannot be undone.')
      if (!confirmed) {
        return
      }
      try {
        const auth = getAuth()
        const user = auth.currentUser

        if (!user) {
          throw new Error('User is not authenticated')
        }

        const projectRef = doc(db, 'USERS', user.uid, 'projects', this.projectData.id)
        await deleteDoc(projectRef)

        this.snackbar.message = 'Project deleted successfully!'
        this.snackbar.color = 'success'
        this.snackbar.show = true

        // Redirect to the dashboard or another appropriate page
        this.$router.push('/dashboard')
      } catch (error) {
        console.error('Error deleting project:', error)
        this.snackbar.message = 'Failed to delete project.'
        this.snackbar.color = 'error'
        this.snackbar.show = true
      }
    },
    async toggleProjectStatus() {
      try {
        const auth = getAuth()
        const user = auth.currentUser

        if (!user) {
          throw new Error('User is not authenticated')
        }

        const projectRef = doc(
          db,
          'USERS',
          user.uid,
          'projects',
          this.projectData.id
        )

        // Toggle the active status
        const newStatus = !this.projectData.active

        // Update the Firestore document
        await updateDoc(projectRef, {
          active: newStatus,
        })

        // Update local data
        this.projectData.active = newStatus

        this.snackbar.message = `Project ${
          newStatus ? 'activated' : 'deactivated'
        } successfully!`
        this.snackbar.color = 'success'
        this.snackbar.show = true
      } catch (error) {
        console.error('Error toggling project status:', error)
        this.snackbar.message = 'Failed to update project status.'
        this.snackbar.color = 'error'
        this.snackbar.show = true
      }
    },
  },
}
</script>

<style scoped>
.v-container {
  max-width: 800px;
  margin: 0 auto;
}

h1 {
  text-align: center;
  margin-bottom: 20px;
}

.v-card-title {
  font-weight: bold;
}

.v-card {
  border: 1px solid #ccc;
  border-radius: 8px;
}
</style>