import { createRouter, createWebHistory } from 'vue-router'
import HomePage from '@/views/Home.vue'
import RegisterPage from '@/views/Register.vue'
import LoginPage from '@/views/Login.vue'
import DashboardPage from '@/views/Dashboard.vue'
import ConfigureDetailsPage from '@/views/ConfigureDetails.vue'
import CreateProjectPage from '@/views/CreateProject.vue'
import ProjectOverviewPage from '@/views/ProjectOverviewPage.vue'
import ProjectDetailsPage from '@/views/ProjectDetailsPage.vue'
import ProjectTimesheetsPage from '@/views/ProjectTimesheetsPage.vue'
import ProjectInvoicesPage from '@/views/ProjectInvoicesPage.vue'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import { getAnalytics, logEvent, setUserId } from 'firebase/analytics'

const routes = [
  { path: '/', name: 'Home', component: HomePage },
  { path: '/register', name: 'Register', component: RegisterPage },
  { path: '/login', name: 'Login', component: LoginPage },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: DashboardPage,
    meta: { requiresAuth: true },
  },
  {
    path: '/configure-details',
    name: 'ConfigureDetails',
    component: ConfigureDetailsPage,
    meta: { requiresAuth: true },
  },
  {
    path: '/create-project',
    name: 'CreateProject',
    component: CreateProjectPage,
    meta: { requiresAuth: true },
  },
  {
    path: '/project/:id',
    name: 'ProjectOverview',
    component: ProjectOverviewPage,
    meta: { requiresAuth: true },
  },
  {
    path: '/project/:id/details',
    name: 'ProjectDetails',
    component: ProjectDetailsPage,
    meta: { requiresAuth: true },
  },
  {
    path: '/project/:id/timesheets',
    name: 'ProjectTimesheets',
    component: ProjectTimesheetsPage,
    meta: { requiresAuth: true },
  },
  {
    path: '/project/:id/invoices',
    name: 'ProjectInvoices',
    component: ProjectInvoicesPage,
    meta: { requiresAuth: true },
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

const auth = getAuth()
const analytics = getAnalytics() // Initialize Firebase Analytics
let isAuthChecked = false

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth)

  if (isAuthChecked) {
    const currentUser = auth.currentUser
    if (requiresAuth && !currentUser) {
      next('/login')
    } else {
      // Log page view for authenticated users
      trackPageView(to)
      next()
    }
  } else {
    onAuthStateChanged(auth, (user) => {
      isAuthChecked = true
      if (user) {
        // Track user ID for analytics
        setUserId(analytics, user.uid)
      }

      if (requiresAuth && !user) {
        next('/login')
      } else {
        // Log page view for authenticated/unauthenticated users
        trackPageView(to)
        next()
      }
    })
  }
})

/**
 * Track page views using Google Analytics
 * @param {RouteLocationNormalized} to - The route being navigated to
 */
function trackPageView(to) {
  logEvent(analytics, 'page_view', {
    page_title: to.name,
    page_path: to.path,
    page_location: window.location.href,
  })
}

export default router
