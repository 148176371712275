// firebaseConfig.js
import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'
import { getFunctions } from 'firebase/functions'
const firebaseConfig = {
    apiKey: "AIzaSyBOhaU_70UgOXFPPmw7kp3-dVePpsQSCr8",
    authDomain: "timecons-6a8aa.firebaseapp.com",
    projectId: "timecons-6a8aa",
    storageBucket: "timecons-6a8aa.appspot.com",
    messagingSenderId: "896821453126",
    appId: "1:896821453126:web:d5138d794e2e692d4d4547",
    measurementId: "G-WWYX04TECM"
  };
  const app = initializeApp(firebaseConfig)
  const auth = getAuth(app)
  const db = getFirestore(app)
  const functions = getFunctions(app) // Initialize functions with the app
  
  export { auth, db, functions }
