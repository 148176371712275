<template>
    <v-container class="register-container">
      <v-row justify="center">
        <v-col cols="12" md="8" lg="6">
          <v-card class="mx-auto">
            <v-card-title class="justify-center">
              Register
            </v-card-title>
            <v-card-text>
              <!-- Error Alert -->
              <v-alert
                v-if="errorMessage"
                type="error"
                dismissible
                @input="errorMessage = ''"
              >
                {{ errorMessage }}
              </v-alert>
              <!-- Success Alert -->
              <v-alert
                v-if="successMessage"
                type="success"
                dismissible
                @input="successMessage = ''"
              >
                {{ successMessage }}
              </v-alert>
  
              <!-- Loading Indicator -->
              <v-progress-linear
                v-if="isLoading"
                indeterminate
                color="primary"
                class="mb-4"
              ></v-progress-linear>
  
              <v-form ref="form" v-model="valid" @submit.prevent="onSubmit">
                <v-text-field
                  v-model="firstName"
                  label="First Name"
                  :rules="firstNameRules"
                  required
                ></v-text-field>
                <v-text-field
                  v-model="lastName"
                  label="Last Name"
                  :rules="lastNameRules"
                  required
                ></v-text-field>
                <v-text-field
                  v-model="email"
                  label="Email"
                  :rules="emailRules"
                  required
                ></v-text-field>
                <v-text-field
                  v-model="password"
                  label="Password"
                  :rules="passwordRules"
                  type="password"
                  required
                ></v-text-field>
                <v-text-field
                  v-model="passwordConfirm"
                  label="Confirm Password"
                  :rules="passwordConfirmRules"
                  type="password"
                  required
                ></v-text-field>
                <v-btn
                  :disabled="isLoading"
                  color="primary"
                  type="submit"
                  block
                >
                  Register
                </v-btn>
              </v-form>
  
              <!-- Link to Login Page -->
              <div class="mt-4 text-center">
                <span>Already have an account?</span>
                <v-btn text color="primary" @click="goToLogin">
                  Login here
                </v-btn>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </template>
  
  <script>
  import { auth, db } from '@/firebaseConfig'
  import { createUserWithEmailAndPassword, updateProfile } from 'firebase/auth'
  import { doc, setDoc } from 'firebase/firestore'
  
  export default {
    name: 'RegisterPage',
    data() {
      return {
        valid: false,
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        passwordConfirm: '',
        isLoading: false,
        errorMessage: '',
        successMessage: '',
        firstNameRules: [
          (v) => !!v || 'First name is required',
          (v) => v.length >= 2 || 'First name must be at least 2 characters',
        ],
        lastNameRules: [
          (v) => !!v || 'Last name is required',
          (v) => v.length >= 2 || 'Last name must be at least 2 characters',
        ],
        emailRules: [
          (v) => !!v || 'Email is required',
          (v) => /.+@.+\..+/.test(v) || 'Email must be valid',
        ],
        passwordRules: [
          (v) => !!v || 'Password is required',
          (v) => v.length >= 6 || 'Password must be at least 6 characters',
        ],
        passwordConfirmRules: [
          (v) => !!v || 'Password confirmation is required',
          // Password matching is handled in onSubmit method
        ],
      }
    },
    methods: {
  goToLogin() {
    this.$router.push('/login')
  },
  async onSubmit() {
    this.errorMessage = ''
    this.successMessage = ''
    if (this.$refs.form.validate()) {
      if (this.password !== this.passwordConfirm) {
        this.errorMessage = 'Passwords do not match'
        return
      }
      this.isLoading = true
      try {
        // Create user with email and password
        const userCredential = await createUserWithEmailAndPassword(
          auth,
          this.email,
          this.password
        )
        const user = userCredential.user

        // Update user profile with full name
        await updateProfile(user, {
          displayName: `${this.firstName} ${this.lastName}`,
        })

        console.log('User registered:', user)

        // Ensure the user is authenticated before writing to Firestore
        if (!auth.currentUser) {
          throw new Error('User is not authenticated')
        }

        console.log('Attempting to save user data to Firestore')

        // Save user data to Firestore
        await setDoc(doc(db, 'USERS', user.uid), {
          firstName: this.firstName,
          lastName: this.lastName,
          personalEmail: this.email,
          uid: user.uid,
          createdAt: new Date(),
        })

        console.log('User data saved to Firestore')

        // Display success message
        this.successMessage = 'Registration successful! Redirecting...'

        // Redirect to dashboard after a brief delay
        setTimeout(() => {
          this.$router.push('/dashboard')
        }, 2000)
      } catch (error) {
        console.error('Registration error:', error)
        console.error('Error code:', error.code)
        // Handle specific errors
        if (error.code) {
          switch (error.code) {
            case 'auth/email-already-in-use':
              this.errorMessage = 'Email is already in use.'
              break
            case 'auth/invalid-email':
              this.errorMessage = 'Invalid email address.'
              break
            case 'auth/weak-password':
              this.errorMessage = 'Password is too weak.'
              break
            case 'permission-denied':
              this.errorMessage =
                'Permission denied. Please contact support if the issue persists.'
              break
            default:
              this.errorMessage =
                error.message || 'An error occurred during registration.'
          }
        } else {
          this.errorMessage =
            error.message || 'An error occurred during registration.'
        }
      } finally {
        this.isLoading = false
      }
    }
  },
},

  }
  </script>
  
  <style scoped>
  .register-container {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  </style>
  