<template>
  <v-container class="login-container">
    <v-row justify="center">
      <v-col cols="12" md="8" lg="6">
        <v-card class="mx-auto">
          <v-card-title class="justify-center">Login</v-card-title>
          <v-card-text>
            <!-- Error Alert -->
            <v-alert
              v-if="errorMessage"
              type="error"
              dismissible
              @input="errorMessage = ''"
            >
              {{ errorMessage }}
            </v-alert>
            <v-form ref="form" v-model="valid" @submit.prevent="onSubmit">
              <v-text-field
                v-model="email"
                label="Email"
                :rules="emailRules"
                required
              ></v-text-field>
              <v-text-field
                v-model="password"
                label="Password"
                :rules="passwordRules"
                type="password"
                required
              ></v-text-field>
              <v-btn color="primary" type="submit" block>Login</v-btn>
            </v-form>
            <!-- Forgot Password Link -->
            <div class="mt-4 text-center">
              <v-btn text color="primary" @click="openResetDialog">
                Forgot Password?
              </v-btn>
            </div>
            <!-- Link to Register Page -->
            <div class="mt-4 text-center">
              <span>Don't have an account?</span>
              <v-btn text color="primary" @click="goToRegister">
                Register here
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- Reset Password Dialog -->
    <v-dialog v-model="resetDialog" max-width="500">
      <v-card>
        <v-card-title class="justify-center">Reset Password</v-card-title>
        <v-card-text>
          <v-alert
            v-if="resetMessage"
            :type="resetSuccess ? 'success' : 'error'"
            dismissible
            @input="resetMessage = ''"
          >
            {{ resetMessage }}
          </v-alert>
          <v-form ref="resetForm" v-model="resetValid" @submit.prevent="onResetPassword">
            <v-text-field
              v-model="resetEmail"
              label="Email"
              :rules="emailRules"
              required
            ></v-text-field>
            <v-btn color="primary" type="submit" block>Send Reset Email</v-btn>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn text color="primary" @click="closeResetDialog">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { auth } from "@/firebaseConfig";
import { signInWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth";

export default {
  name: "LoginPage",
  data() {
    return {
      valid: false,
      email: "",
      password: "",
      errorMessage: "",
      emailRules: [
        (v) => !!v || "Email is required",
        (v) => /.+@.+\..+/.test(v) || "Email must be valid",
      ],
      passwordRules: [(v) => !!v || "Password is required"],

      // Password reset
      resetDialog: false,
      resetEmail: "",
      resetValid: false,
      resetMessage: "",
      resetSuccess: false,
    };
  },
  methods: {
    async onSubmit() {
      this.errorMessage = "";
      if (this.$refs.form.validate()) {
        try {
          await signInWithEmailAndPassword(auth, this.email, this.password);
          // Redirect to dashboard
          this.$router.push("/dashboard");
        } catch (error) {
          console.error("Login error:", error);
          this.errorMessage = "Invalid email or password.";
        }
      }
    },
    goToRegister() {
      this.$router.push("/register");
    },
    openResetDialog() {
      this.resetDialog = true;
    },
    closeResetDialog() {
      this.resetDialog = false;
      this.resetEmail = "";
      this.resetMessage = "";
      this.resetSuccess = false;
    },
    async onResetPassword() {
      this.resetMessage = "";
      if (this.$refs.resetForm.validate()) {
        try {
          await sendPasswordResetEmail(auth, this.resetEmail);
          this.resetMessage = "Password reset email sent. Please check your inbox.";
          this.resetSuccess = true;
        } catch (error) {
          console.error("Password reset error:", error);
          this.resetMessage = "Failed to send password reset email.";
          this.resetSuccess = false;
        }
      }
    },
  },
};
</script>

  
  <style scoped>
  .login-container {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  </style>
  