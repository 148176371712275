import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

// Vuetify imports
import { createVuetify } from 'vuetify'
import 'vuetify/styles'
import '@mdi/font/css/materialdesignicons.css'

import * as components from 'vuetify/components' // Add this line
import * as directives from 'vuetify/directives' // Add this line

const vuetify = createVuetify({
  components, // Add this line
  directives, // Add this line
  theme: {
    defaultTheme: 'myCustomTheme',
    themes: {
      myCustomTheme: {
        dark: false,
        colors: {
          primary: '#4CAF50',
            secondary: '#FFC107',
            accent: '#9C27B0',
            error: '#f44336',
            info: '#2196F3',
            success: '#4CAF50',
            warning: '#FB8C00',
          },
        },
      },
    },
  })
  

createApp(App).use(vuetify).use(router).mount('#app')
