<template>
    <v-app>
      <v-main>
        <v-container fluid>
          <!-- Hero Section -->
          <v-parallax
            height="600"
            src="@/assets/background.jpg"
            class="align-center"
          >
            <div class="text-center text-white">
              <h1 class="display-2 font-weight-bold mb-4">TimeCons</h1>
              <p class="subtitle-1 mb-6">
                Automate your timesheets and invoices effortlessly.
              </p>
              <v-btn
                color="primary"
                large
                class="start-now-btn"
                @click="goToRegister"
              >
                Start Now
              </v-btn>
            </div>
          </v-parallax>
  
          <!-- Features Section -->
          <v-container class="mt-12">
            <v-row>
              <v-col cols="12" md="4" class="text-center">
                <v-icon size="64" color="primary">mdi-clock-check-outline</v-icon>
                <h3 class="font-weight-bold mt-4">Automated Timesheets</h3>
                <p>Generate timesheets automatically with no manual input.</p>
              </v-col>
              <v-col cols="12" md="4" class="text-center">
                <v-icon size="64" color="primary">mdi-email-outline</v-icon>
                <h3 class="font-weight-bold mt-4">Email Integration</h3>
                <p>Receive your timesheets directly via email every month.</p>
              </v-col>
              <v-col cols="12" md="4" class="text-center">
                <v-icon size="64" color="primary">mdi-calendar-star</v-icon>
                <h3 class="font-weight-bold mt-4">Holiday Detection</h3>
                <p>Automatically accounts for holidays in your timesheets.</p>
              </v-col>
            </v-row>
          </v-container>
  
          <!-- Call to Action -->
          <v-container class="text-center my-12">
            <h2 class="mb-4">Ready to Simplify Your Workflow?</h2>
            <v-btn color="primary" large @click="goToRegister">
              Get Started
            </v-btn>
          </v-container>
        </v-container>
      </v-main>
    </v-app>
  </template>
  
  <script>
  import { getAuth, onAuthStateChanged } from 'firebase/auth'
  
  export default {
    name: 'HomePage',
    data() {
      return {
        isAuthenticated: false,
      }
    },
    mounted() {
      const auth = getAuth()
      onAuthStateChanged(auth, (user) => {
        this.isAuthenticated = !!user
      })
    },
    methods: {
      goToRegister() {
        if (this.isAuthenticated) {
          this.$router.push('/dashboard')
        } else {
          this.$router.push('/register')
        }
      },
    },
  }
  </script>
  