<template>
    <v-main>
      <v-container fluid v-if="projectData">
        <h1>{{ projectData.projectName }}</h1>
  
        <!-- Tiles at the Top -->
        <v-row class="mt-4" dense>
          <v-col cols="12" md="4">
            <v-card
              class="tile-card"
              color="blue lighten-2"
              dark
              @click="goToTimesheets"
            >
              <v-card-title class="headline">
                <v-icon large class="mr-3">mdi-file-clock</v-icon>
                Timesheets
              </v-card-title>
              <v-card-text>View and manage your timesheets.</v-card-text>
            </v-card>
          </v-col>
  
          <v-col cols="12" md="4">
            <v-card
              class="tile-card"
              color="green lighten-2"
              dark
              @click="goToInvoices"
            >
              <v-card-title class="headline">
                <v-icon large class="mr-3">mdi-file-document</v-icon>
                Invoices
              </v-card-title>
              <v-card-text>View and manage your invoices.</v-card-text>
            </v-card>
          </v-col>
  
          <v-col cols="12" md="4">
            <v-card
              class="tile-card"
              color="orange lighten-2"
              dark
              @click="goToProjectDetails"
            >
              <v-card-title class="headline">
                <v-icon large class="mr-3">mdi-cog-outline</v-icon>
                Project Details
              </v-card-title>
              <v-card-text>Configure your project settings.</v-card-text>
            </v-card>
          </v-col>
        </v-row>
  
        <!-- Timesheet Navigation -->
        <v-row align="center" justify="space-between" class="my-4">
          <v-btn @click="goToPreviousMonth" :disabled="isPreviousDisabled">
            <v-icon left>mdi-chevron-left</v-icon> Previous Month
          </v-btn>
          <h2>{{ monthYearLabel }}</h2>
          <v-btn @click="goToNextMonth" :disabled="isNextDisabled">
            Next Month <v-icon right>mdi-chevron-right</v-icon>
          </v-btn>
        </v-row>
  
        <!-- Save Button at the Top -->
        <v-btn
          color="primary"
          class="mt-2"
          @click="updateTimesheet"
          :loading="isUpdating"
        >
          SAVE
        </v-btn>
        <!-- Updating Message -->
        <v-alert v-if="isUpdating" type="info" class="mt-2">
          Saving timesheet...
        </v-alert>
  
        <!-- Timesheet Table -->
        <v-progress-linear
          v-if="isTableLoading"
          indeterminate
          color="primary"
          class="mb-2"
        ></v-progress-linear>
  
        <div class="position-relative">
          <v-data-table
            :headers="timesheetHeaders"
            :items="timesheetEntries"
            class="elevation-1 full-width-table"
            disable-pagination
            :itemsPerPage="31"
            hide-default-footer
          >
            <!-- Apply greyed-out class to weekends -->
            <template v-slot:item="{ item }">
              <tr :class="{ 'greyed-out': item.isWeekend }">
                <td>{{ item.date }}</td>
                <td>{{ item.weekday }}</td>
                <td>
                  <v-text-field
                    v-model.number="item.hoursWorked"
                    type="number"
                    min="0"
                    max="24"
                    step="0.1"
                    dense
                    class="hours-input"
                    :class="{ 'red-text': item.hoursWorked == 0 }"
                  ></v-text-field>
                </td>
                <td>
                  <v-text-field
                    v-model="item.publicComments"
                    dense
                    class="comments-input"
                  ></v-text-field>
                </td>
              </tr>
            </template>
          </v-data-table>
  
          <!-- Loading Overlay -->
          <v-overlay :value="isTableLoading" absolute>
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
        </div>
  
        <!-- Success and Error Messages -->
        <v-snackbar v-model="showSuccessSnackbar" color="success" timeout="5000">
          {{ successMessage }}
        </v-snackbar>
        <v-snackbar v-model="showErrorSnackbar" color="error" timeout="5000">
          {{ errorMessage }}
        </v-snackbar>
      </v-container>
  
      <!-- Loading Indicator for Project Data -->
      <v-container v-else>
        <v-row justify="center" align="center" style="height: 300px;">
          <v-progress-circular
            indeterminate
            color="primary"
            size="64"
          ></v-progress-circular>
        </v-row>
      </v-container>
    </v-main>
  </template>
  
  <script>
  import { getAuth, onAuthStateChanged } from "firebase/auth";
  import { doc, getDoc } from "firebase/firestore";
  import { db } from "@/firebaseConfig";
  import { getFunctions, httpsCallable } from "firebase/functions";
  
  export default {
    name: "ProjectOverviewPage",
    data() {
      return {
        projectId: "",
        projectData: null,
        currentMonth: null,
        currentYear: null,
        timesheetEntries: [],
        isUpdating: false,
        isTableLoading: false,
        successMessage: "",
        showSuccessSnackbar: false,
        errorMessage: "",
        showErrorSnackbar: false,
        timesheetHeaders: [
          { text: "Date", value: "date", width: "20%" },
          { text: "Day", value: "weekday", width: "20%" },
          { text: "Hours Worked", value: "hoursWorked", width: "20%" },
          { text: "Comments", value: "publicComments" },
        ],
      };
    },
    computed: {
      monthYearLabel() {
        return `${this.monthName} ${this.currentYear}`;
      },
      monthName() {
        return new Date(
          this.currentYear,
          this.currentMonth - 1
        ).toLocaleString("default", { month: "long" });
      },
      isPreviousDisabled() {
        return false; // Adjust based on project constraints
      },
      isNextDisabled() {
        return false; // Adjust based on project constraints
      },
    },
    async created() {
      const auth = getAuth();
      onAuthStateChanged(auth, async (user) => {
        if (user) {
          this.projectId = this.$route.params.id;
          await this.fetchProjectData(user.uid);
          const today = new Date();
          this.currentMonth = today.getMonth() + 1;
          this.currentYear = today.getFullYear();
          await this.loadTimesheet();
        } else {
          this.$router.push("/login");
        }
      });
    },
    methods: {
      async fetchProjectData(userId) {
        try {
          const projectRef = doc(db, "USERS", userId, "projects", this.projectId);
          const projectSnap = await getDoc(projectRef);
          if (projectSnap.exists()) {
            this.projectData = projectSnap.data();
          } else {
            this.$router.push("/dashboard");
          }
        } catch (error) {
          console.error("Error fetching project:", error);
          this.$router.push("/dashboard");
        }
      },
      async loadTimesheet() {
        this.isTableLoading = true; // Start loading animation
        try {
          const functions = getFunctions();
          const generateTimesheet = httpsCallable(
            functions,
            "generateTimesheetOnDemand"
          );
          const result = await generateTimesheet({
            projectId: this.projectId,
            month: this.currentMonth,
            year: this.currentYear,
          });
          this.timesheetEntries = result.data.timesheetData.entries.map(
            (entry) => ({
              ...entry,
              isWeekend: [0, 6].includes(new Date(entry.date).getDay()),
            })
          );
        } catch (error) {
          console.error("Error loading timesheet:", error);
          this.errorMessage = "Failed to load timesheet.";
          this.showErrorSnackbar = true;
        } finally {
          this.isTableLoading = false; // Stop loading animation
        }
      },
      async updateTimesheet() {
        this.isUpdating = true;
        try {
          const functions = getFunctions();
          const updateTimesheetEntry = httpsCallable(
            functions,
            "updateTimesheetEntry"
          );
          const result = await updateTimesheetEntry({
            projectId: this.projectId,
            month: this.currentMonth,
            year: this.currentYear,
            updatedEntries: this.timesheetEntries,
          });
          console.log(result.data.message);
          this.successMessage = "Timesheet updated successfully.";
          this.showSuccessSnackbar = true;
        } catch (error) {
          console.error("Error updating timesheet:", error);
          this.errorMessage = "Failed to update timesheet.";
          this.showErrorSnackbar = true;
        } finally {
          this.isUpdating = false;
        }
      },
      goToPreviousMonth() {
        const date = new Date(this.currentYear, this.currentMonth - 2); // Subtract 1 month
        this.currentMonth = date.getMonth() + 1;
        this.currentYear = date.getFullYear();
        this.loadTimesheet();
      },
      goToNextMonth() {
        const date = new Date(this.currentYear, this.currentMonth); // Add 1 month
        this.currentMonth = date.getMonth() + 1;
        this.currentYear = date.getFullYear();
        this.loadTimesheet();
      },
      goToTimesheets() {
        this.$router.push({
          name: "ProjectTimesheets",
          params: { id: this.projectId },
        });
      },
      goToInvoices() {
        this.$router.push({
          name: "ProjectInvoices",
          params: { id: this.projectId },
        });
      },
      goToProjectDetails() {
        this.$router.push({
          name: "ProjectDetails",
          params: { id: this.projectId },
        });
      },
    },
  };
  </script>
  
  <style scoped>
  .tile-card {
    cursor: pointer;
    min-height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  /* Table adjustments */
  .position-relative {
    width: 100%;
  }
  .full-width-table {
    width: 100%;
    table-layout: auto;
  }
  
  /* Styling for weekends */
  .greyed-out {
    background-color: #f5f5f5;
  }
  
  /* Input field adjustments */
  .hours-input,
  .comments-input {
    width: 100%;
  }
  
  /* Red text for zero hours worked */
  .red-text input {
    color: red !important;
  }
  </style>
  