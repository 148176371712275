<template>
    <v-main>
      <v-container>
        <h1>Create New Project/Client</h1>
        <v-form ref="form" v-model="valid" @submit.prevent="onSubmit">
          <!-- Project Name -->
          <v-card class="mb-6">
            <v-card-title>Project Name</v-card-title>
            <v-card-text>
              <v-text-field
                v-model="projectName"
                label="Project Name"
                :rules="projectNameRules"
                required
              ></v-text-field>
            </v-card-text>
          </v-card>
  
          <!-- Client Email -->
          <v-card class="mb-6">
            <v-card-title>Client Company Email</v-card-title>
            <v-card-text>
              <v-text-field
                v-model="clientEmail"
                label="Client Email"
                :rules="emailRules"
                required
                hint="This is the email you were given at the client's company, we will be sending timesheets to it"
                persistent-hint
              ></v-text-field>
            </v-card-text>
          </v-card>
  
          <!-- Intermediary Selection -->
          <v-card class="mb-6">
            <v-card-title>Do you use an intermediary company?</v-card-title>
            <v-card-text>
              <v-radio-group v-model="usesIntermediary" row>
                <v-radio label="Yes" value="yes"></v-radio>
                <v-radio label="No" value="no"></v-radio>
              </v-radio-group>
            </v-card-text>
          </v-card>
  
          <!-- Intermediary Company Information -->
          <v-card class="mb-6" v-if="usesIntermediary === 'yes'">
            <v-card-title>1. Intermediary Company Information</v-card-title>
            <v-card-text>
              <!-- VAT Number Field and Retrieve Info Button -->
              <v-row align="center">
                <v-col cols="12" sm="8">
                  <v-text-field
                    v-model="intermediaryVatNumber"
                    label="Intermediary VAT Number"
                    :rules="vatNumberRules"
                    
                    hint="Enter the VAT number of the intermediary company"
                    persistent-hint
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-btn
                    :loading="loadingIntermediary"
                    color="primary"
                    @click="validateIntermediaryVATNumber"
                    block
                  >
                    Retrieve Info
                  </v-btn>
                </v-col>
              </v-row>
  
              <!-- Intermediary Company Name Field -->
              <v-text-field
                v-model="intermediaryCompanyName"
                label="Intermediary Company Name"
                :rules="companyNameRules"
                required
              ></v-text-field>
  
              <!-- Intermediary Street Address Field -->
              <v-text-field
                v-model="intermediaryStreetAddress"
                label="Street Address"
                :rules="streetAddressRules"
                required
              ></v-text-field>
  
              <!-- Intermediary City Name Field -->
              <v-text-field
                v-model="intermediaryCityName"
                label="City"
                :rules="cityNameRules"
                required
              ></v-text-field>
  
              <!-- Intermediary Postal Code Field -->
              <v-text-field
                v-model="intermediaryPostalCode"
                label="Postal Code"
                :rules="postalCodeRules"
                required
              ></v-text-field>
  
              <!-- Intermediary Country Field -->
              <v-select
                v-model="intermediaryCountry"
                :items="countryOptions"
                label="Country"
                :rules="companyCountryRules"
                item-title="text"
                item-value="value"
                required
              ></v-select>
  
              <!-- Reference Number -->
              <v-text-field
                v-model="referenceNumber"
                label="Reference Number"
                hint="Optional reference number for the intermediary company"
                persistent-hint
              ></v-text-field>
            </v-card-text>
          </v-card>
  
          <!-- Client Company Information -->
          <v-card class="mb-6">
            <v-card-title>2. Client Company (Purchaser) Information</v-card-title>
            <v-card-text>
              <!-- Conditional VAT Number Field and Retrieve Info Button -->
              <v-row align="center" v-if="usesIntermediary === 'no'">
                <v-col cols="12" sm="8">
                  <v-text-field
                    v-model="clientVatNumber"
                    label="Client VAT Number"
                    :rules="vatNumberRules"
                    
                    hint="Enter the VAT number of the client company"
                    persistent-hint
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-btn
                    :loading="loadingClient"
                    color="primary"
                    @click="validateClientVATNumber"
                    block
                  >
                    Retrieve Info
                  </v-btn>
                </v-col>
              </v-row>
  
              <!-- Client Company Name Field -->
              <v-text-field
                v-model="clientCompanyName"
                label="Client Company Name"
                :rules="companyNameRules"
                required
              ></v-text-field>
  
              <!-- Client Street Address Field -->
              <v-text-field
                v-model="clientStreetAddress"
                label="Street Address"
                :rules="streetAddressRules"
                v-if="usesIntermediary === 'no'"
                required
              ></v-text-field>
  
              <!-- Client City Name Field -->
              <v-text-field
                v-model="clientCityName"
                label="City"
                :rules="cityNameRules"
                v-if="usesIntermediary === 'no'"
                required
              ></v-text-field>
  
              <!-- Client Postal Code Field -->
              <v-text-field
                v-model="clientPostalCode"
                label="Postal Code"
                :rules="postalCodeRules"
                v-if="usesIntermediary === 'no'"
                required
              ></v-text-field>
  
              <!-- Client Country Field -->
              <v-select
                v-model="clientCountry"
                :items="countryOptions"
                label="Country"
                :rules="companyCountryRules"
                item-title="text"
                item-value="value"
                v-if="usesIntermediary === 'no'"
                required
              ></v-select>
  
              <!-- Manager Name Field -->
              <v-text-field
                v-model="managerName"
                label="Manager Name (optional)"
                hint="Name of your contact person at the client company"
                persistent-hint
              ></v-text-field>
            </v-card-text>
          </v-card>
  
          <!-- Project Details -->
          <v-card class="mb-6">
            <v-card-title>3. Project Details</v-card-title>
            <v-card-text>
              <!-- Project Description -->
              <v-textarea
                v-model="projectDescription"
                label="Project Description"
                :rules="projectDescriptionRules"
                hint="Brief description of the project (e.g., IT support)"
                persistent-hint
                required
              ></v-textarea>
  
              <!-- Rate Input -->
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    v-model.number="hourlyRate"
                    label="Hourly Rate"
                    type="number"
                    :rules="rateRules"
                    @input="calculateDayRate"
                    hint="Enter your hourly rate"
                    persistent-hint
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model.number="dayRate"
                    label="Day Rate"
                    type="number"
                    :rules="rateRules"
                    @input="calculateHourlyRate"
                    hint="Enter your daily rate"
                    persistent-hint
                  ></v-text-field>
                </v-col>
              </v-row>
  
              <!-- Hours per Work Day -->
              <v-text-field
                v-model.number="workDayHours"
                label="Hours per Work Day"
                type="number"
                :rules="workDayHoursRules"
                hint="Default number of working hours in a day (adjustable)"
                persistent-hint
                @input="recalculateRates"
              ></v-text-field>
  
              <!-- Calculated Rate Display -->
              <v-alert
                v-if="hourlyRate && dayRate"
                type="info"
                class="mt-4"
              >
                Based on {{ workDayHours }} hours per day, your rates are:
                Hourly Rate: {{ hourlyRate.toFixed(2) }},
                Day Rate: {{ dayRate.toFixed(2) }}
              </v-alert>
  
              <!-- Work Days Selection -->
              <h3>Default Work Days</h3>
              <p>Select the days you will work by default. This helps generate your default timesheet.</p>
              <v-btn-toggle v-model="workDays" multiple>
                <v-btn v-for="day in weekdays" :key="day.value" :value="day.value">
                  {{ day.text }}
                </v-btn>
              </v-btn-toggle>
            </v-card-text>
          </v-card>
  
          <!-- Advanced Options -->
          <v-card class="mb-6">
            <v-card-title>
              <v-btn color="primary" @click="advancedOptions = !advancedOptions">
                {{ advancedOptions ? 'Hide' : 'Show' }} Advanced Options
              </v-btn>
            </v-card-title>
            <v-card-text v-if="advancedOptions">
              <!-- Start Date -->
              <v-menu
                ref="startDateMenu"
                v-model="startDateMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template #activator="{ on, attrs }">
                  <v-text-field
                    v-model="startDate"
                    label="Project Start Date"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    hint="Optional start date of the project"
                    persistent-hint
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="startDate"
                  @input="startDateMenu = false"
                ></v-date-picker>
              </v-menu>
  
              <!-- End Date -->
              <v-menu
                ref="endDateMenu"
                v-model="endDateMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template #activator="{ on, attrs }">
                  <v-text-field
                    v-model="endDate"
                    label="Project End Date"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    hint="Optional end date of the project"
                    persistent-hint
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="endDate"
                  @input="endDateMenu = false"
                ></v-date-picker>
              </v-menu>
  
              <!-- Manager Email Field -->
              <v-text-field
                v-model="managerEmail"
                label="Manager Email (optional)"
                hint="Email address of your contact person at the client company"
                persistent-hint
              ></v-text-field>
  
              <!-- Invoice Send Date -->
              <v-select
                v-model="invoiceSendDate"
                :items="invoiceSendDateOptions"
                label="When should we send the invoice and timesheet?"
                hint="Default is last day of the month"
                persistent-hint
              ></v-select>
  
              <!-- Holiday Calendar -->
              <v-select
                v-model="holidayCalendar"
                :items="countryOptions"
                label="Holiday Calendar"
                item-title="text"
                item-value="value"
                hint="Country whose holidays will be considered (default is your company country)"
                persistent-hint
              ></v-select>
  
              <!-- Timesheet Mail Time -->
              <v-text-field
                v-model="timesheetMailTime"
                label="When the daily timesheet is sent"
                type="time"
                hint="Time when timesheet emails are sent (default is 08:00)"
                persistent-hint
              ></v-text-field>
            </v-card-text>
          </v-card>
  
          <!-- Submit Button -->
          <v-btn color="primary" type="submit">Create Project</v-btn>
        </v-form>
  
        <!-- Snackbar for User Feedback -->
        <v-snackbar
          v-model="snackbar.show"
          :color="snackbar.color"
          timeout="3000"
          top
          right
        >
          {{ snackbar.message }}
          <v-btn color="white" text @click="snackbar.show = false">Close</v-btn>
        </v-snackbar>
      </v-container>
    </v-main>
  </template>
  
<script>
import { getAuth } from 'firebase/auth';
import { collection, addDoc, doc, getDoc, serverTimestamp } from 'firebase/firestore';
import { db, functions } from '@/firebaseConfig';
import { httpsCallable } from 'firebase/functions';

export default {
  name: 'CreateProjectPage',
  data() {
    return {
        valid: false,
        loadingIntermediary: false,
        loadingClient: false,
        advancedOptions: false,
        clientEmail: '',
        // Project Name
        projectName: '',
        // Intermediary Selection
        usesIntermediary: 'yes', // Default to 'no' or 'yes' as needed
        // Intermediary Company Information
        intermediaryVatNumber: '',
        intermediaryCompanyName: '',
        intermediaryStreetAddress: '',
        intermediaryCityName: '',
        intermediaryPostalCode: '',
        intermediaryCountry: '',
        referenceNumber: '',
        // Client Company Information
        clientVatNumber: '',
        clientCompanyName: '',
        clientStreetAddress: '',
        clientCityName: '',
        clientPostalCode: '',
        clientCountry: '',
        managerName: '',
        managerEmail: '',
        // Project Details
        projectDescription: '',
        // Rate Details
        hourlyRate: null,
        dayRate: null,
        workDayHours: 8,
        workDays: [],
        // Advanced Options
        startDate: '',
        endDate: '',
        startDateMenu: false,
        endDateMenu: false,
        invoiceSendDate: 'Last day of the month',
        holidayCalendar: '',
        timesheetMailTime: '08:00',
        // Snackbar for user feedback
        snackbar: {
          show: false,
          message: '',
          color: 'success',
        },
        // Validation Rules
        projectNameRules: [(v) => !!v || 'Project name is required'],
        vatNumberRules: [(v) => !!v || 'VAT number is required'],
        companyNameRules: [(v) => !!v || 'Company name is required'],
        streetAddressRules: [(v) => !!v || 'Street address is required'],
        cityNameRules: [(v) => !!v || 'City name is required'],
        postalCodeRules: [(v) => !!v || 'Postal code is required'],
        companyCountryRules: [(v) => !!v || 'Please select a country'],
        projectDescriptionRules: [(v) => !!v || 'Project description is required'],
        rateRules: [(v) => v >= 0 || 'Rate must be a positive number'],
        workDayHoursRules: [(v) => v > 0 || 'Must be greater than 0'],
        emailRules: [
          (v) => !!v || 'Client email is required',
          (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
        ],
        // Options
      // Options
      countryOptions: [
        { text: 'Argentina', value: 'AR' },
        { text: 'Australia', value: 'AU' },
        { text: 'Austria', value: 'AT' },
        { text: 'Belgium', value: 'BE' },
        { text: 'Brazil', value: 'BR' },
        { text: 'Canada', value: 'CA' },
        { text: 'Chile', value: 'CL' },
        { text: 'China', value: 'CN' },
        { text: 'Colombia', value: 'CO' },
        { text: 'Bulgaria', value: 'BG' },
        { text: 'Croatia', value: 'HR' },
        { text: 'Cyprus', value: 'CY' },
        { text: 'Czech Republic', value: 'CZ' },
        { text: 'Denmark', value: 'DK' },
        { text: 'Egypt', value: 'EG' },
        { text: 'Estonia', value: 'EE' },
        { text: 'Finland', value: 'FI' },
        { text: 'France', value: 'FR' },
        { text: 'Germany', value: 'DE' },
        { text: 'Greece', value: 'GR' },
        { text: 'Hong Kong', value: 'HK' },
        { text: 'Hungary', value: 'HU' },
        { text: 'Iceland', value: 'IS' },
        { text: 'India', value: 'IN' },
        { text: 'Indonesia', value: 'ID' },
        { text: 'Ireland', value: 'IE' },
        { text: 'Israel', value: 'IL' },
        { text: 'Italy', value: 'IT' },
        { text: 'Japan', value: 'JP' },
        { text: 'Latvia', value: 'LV' },
        { text: 'Lithuania', value: 'LT' },
        { text: 'Luxembourg', value: 'LU' },
        { text: 'Malaysia', value: 'MY' },
        { text: 'Mexico', value: 'MX' },
        { text: 'Malta', value: 'MT' },
        { text: 'Netherlands', value: 'NL' },
        { text: 'New Zealand', value: 'NZ' },
        { text: 'Nigeria', value: 'NG' },
        { text: 'Norway', value: 'NO' },
        { text: 'Peru', value: 'PE' },
        { text: 'Philippines', value: 'PH' },
        { text: 'Poland', value: 'PL' },
        { text: 'Portugal', value: 'PT' },
        { text: 'Russia', value: 'RU' },
        { text: 'Saudi Arabia', value: 'SA' },
        { text: 'Singapore', value: 'SG' },
        { text: 'Romania', value: 'RO' },
        { text: 'Slovakia', value: 'SK' },
        { text: 'Slovenia', value: 'SI' },
        { text: 'South Africa', value: 'ZA' },
        { text: 'South Korea', value: 'KR' },
        { text: 'Spain', value: 'ES' },
        { text: 'Sweden', value: 'SE' },
        { text: 'Switzerland', value: 'CH' },
        { text: 'Taiwan', value: 'TW' },
        { text: 'Thailand', value: 'TH' },
        { text: 'Turkey', value: 'TR' },
        { text: 'United Arab Emirates', value: 'AE' },
        { text: 'United Kingdom', value: 'GB' },
        { text: 'United States', value: 'US' },
        { text: 'Venezuela', value: 'VE' },
        { text: 'Vietnam', value: 'VN' },
      ],
      invoiceSendDateOptions: ['Last day of the month', 'Custom Date'],
      weekdays: [
        { text: 'Mon', value: 'Monday' },
        { text: 'Tue', value: 'Tuesday' },
        { text: 'Wed', value: 'Wednesday' },
        { text: 'Thu', value: 'Thursday' },
        { text: 'Fri', value: 'Friday' },
        { text: 'Sat', value: 'Saturday' },
        { text: 'Sun', value: 'Sunday' },
      ],
    }
  }, async mounted() {
    try {
      const auth = getAuth();
      const user = auth.currentUser;

      if (!user) {
        throw new Error('User is not authenticated');
      }

      // Fetch the user's data from Firestore
      const userDocRef = doc(db, 'USERS', user.uid);
      const userDocSnap = await getDoc(userDocRef);

      if (userDocSnap.exists()) {
        const userData = userDocSnap.data();

        // Set the holidayCalendar to the user's country
        this.holidayCalendar = userData.companyCountry || '';

        // Optionally, set other defaults based on user data
      } else {
        console.warn('User data not found in Firestore');
        // Optionally set a default value
        this.holidayCalendar = ''; // Or set to a default country code
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
      // Handle error (e.g., show a snackbar message)
      this.snackbar.message = 'Failed to load user data.';
      this.snackbar.color = 'error';
      this.snackbar.show = true;
    }
  },
  methods: {
    calculateDayRate() {
      if (this.hourlyRate && this.workDayHours) {
        this.dayRate = this.hourlyRate * this.workDayHours
      }
    },
    calculateHourlyRate() {
      if (this.dayRate && this.workDayHours) {
        this.hourlyRate = this.dayRate / this.workDayHours
      }
    },
    recalculateRates() {
      if (this.hourlyRate) {
        this.calculateDayRate()
      } else if (this.dayRate) {
        this.calculateHourlyRate()
      }
    },
      async validateIntermediaryVATNumber() {
        if (!this.intermediaryVatNumber) {
          this.snackbar.message = 'Please enter a VAT number.'
          this.snackbar.color = 'error'
          this.snackbar.show = true
          return
        }
        try {
          this.loadingIntermediary = true // Start loading animation
  
          // Format VAT number by removing non-alphanumeric characters
          const vatNumber = this.intermediaryVatNumber.replace(/[^a-zA-Z0-9]/g, '').toUpperCase()
  
          const validateVAT = httpsCallable(functions, 'validateVAT')
          const result = await validateVAT({ vatNumber })
          console.log('validateVAT result:', result)
  
          // Check if the VAT number is registered
          if (
            result.data.registration_info &&
            result.data.registration_info.is_registered
          ) {
            // Pre-fill company details based on API response
            this.intermediaryCompanyName =
              result.data.registration_info.name || this.intermediaryCompanyName
  
            // Split the address into components
            if (result.data.registration_info.address) {
              const address = result.data.registration_info.address
              this.parseIntermediaryAddress(address)
            }
  
            this.intermediaryCountry =
              result.data.country_code || this.intermediaryCountry
  
            this.snackbar.message =
              'VAT number is valid. Company details have been pre-filled.'
            this.snackbar.color = 'success'
            this.snackbar.show = true
          } else {
            this.snackbar.message = 'Invalid VAT number.'
            this.snackbar.color = 'error'
            this.snackbar.show = true
          }
        } catch (error) {
          console.error('VAT validation error:', error)
          this.snackbar.message = 'Failed to validate VAT number.'
          this.snackbar.color = 'error'
          this.snackbar.show = true
        } finally {
          this.loadingIntermediary = false // Stop loading animation
        }
      },
      async validateClientVATNumber() {
        if (!this.clientVatNumber) {
          this.snackbar.message = 'Please enter a VAT number.'
          this.snackbar.color = 'error'
          this.snackbar.show = true
          return
        }
        try {
          this.loadingClient = true // Start loading animation
  
          // Format VAT number by removing non-alphanumeric characters
          const vatNumber = this.clientVatNumber.replace(/[^a-zA-Z0-9]/g, '').toUpperCase()
  
          const validateVAT = httpsCallable(functions, 'validateVAT')
          const result = await validateVAT({ vatNumber })
          console.log('validateVAT result:', result)
  
          // Check if the VAT number is registered
          if (
            result.data.registration_info &&
            result.data.registration_info.is_registered
          ) {
            // Pre-fill company details based on API response
            this.clientCompanyName =
              result.data.registration_info.name || this.clientCompanyName
  
            // Split the address into components
            if (result.data.registration_info.address) {
              const address = result.data.registration_info.address
              this.parseClientAddress(address)
            }
  
            this.clientCountry =
              result.data.country_code || this.clientCountry
  
            this.snackbar.message =
              'VAT number is valid. Company details have been pre-filled.'
            this.snackbar.color = 'success'
            this.snackbar.show = true
          } else {
            this.snackbar.message = 'Invalid VAT number.'
            this.snackbar.color = 'error'
            this.snackbar.show = true
          }
        } catch (error) {
          console.error('VAT validation error:', error)
          this.snackbar.message = 'Failed to validate VAT number.'
          this.snackbar.color = 'error'
          this.snackbar.show = true
        } finally {
          this.loadingClient = false // Stop loading animation
        }
      },
      parseIntermediaryAddress(address) {
        // Assuming the address is in the format "Street Address\nPostal Code City"
        const lines = address.split('\n')
        if (lines.length >= 2) {
          this.intermediaryStreetAddress = lines[0]
          const cityLine = lines[1]
          const match = cityLine.match(/^(\d{4,6})\s+(.+)/)
          if (match) {
            this.intermediaryPostalCode = match[1]
            this.intermediaryCityName = match[2]
          } else {
            this.intermediaryCityName = cityLine
          }
        } else {
          this.intermediaryStreetAddress = address
        }
      },
      parseClientAddress(address) {
        // Similar to parseIntermediaryAddress
        const lines = address.split('\n')
        if (lines.length >= 2) {
          this.clientStreetAddress = lines[0]
          const cityLine = lines[1]
          const match = cityLine.match(/^(\d{4,6})\s+(.+)/)
          if (match) {
            this.clientPostalCode = match[1]
            this.clientCityName = match[2]
          } else {
            this.clientCityName = cityLine
          }
        } else {
          this.clientStreetAddress = address
        }
      },
      async onSubmit() {
      if (this.$refs.form.validate()) {
        try {
          const auth = getAuth();
          const user = auth.currentUser;

          if (!user) {
            throw new Error('User is not authenticated');
          }

          // Prepare data to save
          const projectData = {
            projectName: this.projectName,
            clientEmail: this.clientEmail,
            usesIntermediary: this.usesIntermediary === 'yes',
            intermediaryCompany:
              this.usesIntermediary === 'yes'
                ? {
                    vatNumber: this.intermediaryVatNumber,
                    companyName: this.intermediaryCompanyName,
                    streetAddress: this.intermediaryStreetAddress,
                    cityName: this.intermediaryCityName,
                    postalCode: this.intermediaryPostalCode,
                    country: this.intermediaryCountry,
                    referenceNumber: this.referenceNumber,
                  }
                : null,
            clientCompany: {
              vatNumber:
                this.usesIntermediary === 'no' ? this.clientVatNumber : null,
              companyName: this.clientCompanyName,
              streetAddress:
                this.usesIntermediary === 'no'
                  ? this.clientStreetAddress
                  : null,
              cityName:
                this.usesIntermediary === 'no' ? this.clientCityName : null,
              postalCode:
                this.usesIntermediary === 'no' ? this.clientPostalCode : null,
              country:
                this.usesIntermediary === 'no' ? this.clientCountry : null,
              managerName: this.managerName,
              managerEmail: this.managerEmail || '',
            },
            projectDetails: {
              description: this.projectDescription,
              hourlyRate: this.hourlyRate,
              dayRate: this.dayRate,
              workDayHours: this.workDayHours,
              workDays: this.workDays,
              startDate: this.startDate || '',
              endDate: this.endDate || '',
              invoiceSendDate: this.invoiceSendDate || 'Last day of the month',
              holidayCalendar: this.holidayCalendar || '', // Ensure this is included
              timesheetMailTime: this.timesheetMailTime || '08:00',
            },
            createdAt: serverTimestamp(),
            active: true, // Set the project as active upon creation
          };

          // Save the project details to Firestore under the user's projects collection
          const projectsCollectionRef = collection(
            db,
            'USERS',
            user.uid,
            'projects'
          );
          await addDoc(projectsCollectionRef, projectData);

          // Provide user feedback
          this.snackbar.message = 'Project created successfully!';
          this.snackbar.color = 'success';
          this.snackbar.show = true;

          // Redirect to the dashboard
          this.$router.push('/dashboard');
        } catch (error) {
          console.error('Error saving project:', error)
          this.snackbar.message = 'Failed to create project.'
          this.snackbar.color = 'error'
          this.snackbar.show = true
        }
      }
    },
  },
}
</script>

<style scoped>
.v-container {
  max-width: 800px;
  margin: 0 auto;
}

h1 {
  text-align: center;
  margin-bottom: 20px;
}

.v-card-title {
  font-weight: bold;
}

.v-card {
  border: 1px solid #ccc;
  border-radius: 8px;
}
</style>