<template>
  <v-main>
    <v-container class="dashboard-container">
      <v-row>
        <v-col>
          <h1>Welcome, {{ userName }}!</h1>
          <p>This is your dashboard.</p>
        </v-col>
      </v-row>

      <!-- Tiles Row -->
      <v-row class="mt-4" dense>
        <!-- Configure Your Details Tile -->
        <v-col cols="12" md="6">
          <v-card
            class="tile-card"
            color="blue lighten-2"
            dark
            @click="goToConfigureDetails"
          >
            <v-card-title class="headline">
              <v-icon large class="mr-3">mdi-account-cog</v-icon>
              Your Information
            </v-card-title>
            <v-card-text>
              Update your personal information and preferences.
            </v-card-text>
          </v-card>
        </v-col>

        <!-- Create New Project/Client Tile -->
        <v-col cols="12" md="6">
          <v-card
            class="tile-card"
            color="green lighten-2"
            dark
            @click="goToCreateProject"
          >
            <v-card-title class="headline">
              <v-icon large class="mr-3">mdi-briefcase-plus</v-icon>
              Create New Project/Client
            </v-card-title>
            <v-card-text>
              Set up a new project or add a client.
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <!-- Projects List -->
      <v-row class="mt-4">
        <v-col cols="12">
          <h2>Your Projects</h2>
        </v-col>
        <v-col cols="12" md="4" v-for="project in projects" :key="project.id">
          <v-card class="project-card" @click="goToProjectOverview(project.id)">
            <v-card-title>{{ project.projectName }}</v-card-title>
            <v-card-subtitle>
              Created on: {{ formatDate(project.createdAt) }}
            </v-card-subtitle>
            <v-card-text>
              {{ project.projectDetails.description }}
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import { db } from '@/firebaseConfig'
import { collection, query, orderBy, getDocs } from 'firebase/firestore'

export default {
  name: 'DashboardPage',
  data() {
    return {
      userName: '',
      projects: [],
    }
  },
  created() {
    const auth = getAuth()
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        this.userName = user.displayName || user.email
        await this.fetchProjects(user.uid)
      } else {
        // Redirect to login if not authenticated
        this.$router.push('/login')
      }
    })
  },
  methods: {
    async fetchProjects(userId) {
      try {
        const projectsRef = collection(db, 'USERS', userId, 'projects')
        const q = query(projectsRef, orderBy('createdAt', 'desc'))
        const querySnapshot = await getDocs(q)
        this.projects = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }))
      } catch (error) {
        console.error('Error fetching projects:', error)
      }
    },
    formatDate(timestamp) {
      if (!timestamp) return ''
      const date = timestamp.toDate()
      return date.toLocaleDateString()
    },
    goToConfigureDetails() {
      this.$router.push('/configure-details')
    },
    goToCreateProject() {
      this.$router.push('/create-project')
    },
    goToProjectOverview(projectId) {
      // Redirect to the project overview page
      this.$router.push({ name: 'ProjectOverview', params: { id: projectId } })
    },
  },
}
</script>

<style scoped>
.dashboard-container {
  margin-top: 20px;
}

.tile-card,
.project-card {
  cursor: pointer;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.v-card-title {
  display: flex;
  align-items: center;
}

.project-card {
  border: 1px solid #ccc;
  border-radius: 8px;
}
</style>
