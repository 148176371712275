<template>
    <v-app-bar app color="primary" dark>
      <v-toolbar-title class="cursor-pointer" @click="goToHome">
        TimeCons
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn text v-if="!isAuthenticated" @click="$router.push('/login')">
        Login
      </v-btn>
      <v-btn text v-if="!isAuthenticated" @click="$router.push('/register')">
        Register
      </v-btn>
      <v-btn text v-if="isAuthenticated" @click="signOut">
        Sign Out
      </v-btn>
    </v-app-bar>
  </template>
  
  <script>
  import { getAuth, signOut, onAuthStateChanged } from 'firebase/auth'
  
  export default {
    name: 'NavBar',
    data() {
      return {
        isAuthenticated: false,
      }
    },
    mounted() {
      const auth = getAuth()
      onAuthStateChanged(auth, (user) => {
        this.isAuthenticated = !!user
      })
    },
    methods: {
      goToHome() {
        if (this.isAuthenticated) {
          this.$router.push('/dashboard')
        } else {
          this.$router.push('/')
        }
      },
      async signOut() {
        const auth = getAuth()
        try {
          await signOut(auth)
          this.$router.push('/')
        } catch (error) {
          console.error('Sign out error:', error)
        }
      },
    },
  }
  </script>
  
  <style scoped>
  .cursor-pointer {
    cursor: pointer;
  }
  </style>
  