<template>
    <v-main>
      <v-container v-if="projectData">
        <h1>Timesheets for {{ projectData.projectName }}</h1>
  
        <v-data-table
          :headers="timesheetHeaders"
          :items="timesheetItems"
          class="elevation-1"
        >
          <template v-slot:[`item.month`]="{ item }">
            {{ item.month }}
          </template>
          <template v-slot:[`item.timesheet`]="{ item }">
            <v-btn
              v-if="item.timesheetExcelUrl"
              :href="item.timesheetExcelUrl"
              target="_blank"
              color="primary"
              text
            >
              Download Timesheet
            </v-btn>
            <span v-else>No timesheet</span>
          </template>
          <!-- Actions Column Slot -->
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn
              :loading="item.isLoading" 
              color="primary"
              text
              @click="triggerTimesheetGeneration(item)"
            >
              Generate Latest Timesheet
            </v-btn>
          </template>
        </v-data-table>
  
        <!-- Success Message -->
        <v-snackbar v-model="showSuccessSnackbar" color="success" timeout="5000">
          {{ successMessage }}
        </v-snackbar>
  
        <!-- Error Message -->
        <v-snackbar v-model="showErrorSnackbar" color="error" timeout="5000">
          {{ errorMessage }}
        </v-snackbar>
      </v-container>
  
      <!-- Loading Indicator when projectData is not loaded yet -->
      <v-container v-else>
        <v-row justify="center" align="center" style="height: 300px;">
          <v-progress-circular indeterminate color="primary" size="64"></v-progress-circular>
        </v-row>
      </v-container>
    </v-main>
  </template>
  <script>
  import { getAuth, onAuthStateChanged } from 'firebase/auth'
  import { collection, getDocs, doc, getDoc } from 'firebase/firestore'
  import { getFunctions, httpsCallable } from 'firebase/functions'
  import { db } from '@/firebaseConfig'
  
  export default {
    name: 'ProjectTimesheetsPage',
    data() {
      return {
        projectId: '',
        projectData: null,
        timesheetHeaders: [
          { text: 'Month', value: 'month' },
          { text: 'Timesheet', value: 'timesheet' },
          { text: 'Actions', value: 'actions', sortable: false },
        ],
        timesheetItems: [],
        successMessage: '',        // Message content
        showSuccessSnackbar: false,// Controls visibility
        errorMessage: '',          // Message content
        showErrorSnackbar: false,  // Controls visibility
      }
    },
    async created() {
      const auth = getAuth()
      onAuthStateChanged(auth, async (user) => {
        if (user) {
          this.projectId = this.$route.params.id
          await this.fetchProjectData(user.uid)
          await this.fetchTimesheets(user.uid)
        } else {
          this.$router.push('/login')
        }
      })
    },
    methods: {
      async fetchProjectData(userId) {
        try {
          const projectRef = doc(db, 'USERS', userId, 'projects', this.projectId)
          const projectSnap = await getDoc(projectRef)
          if (projectSnap.exists()) {
            this.projectData = projectSnap.data()
          } else {
            this.$router.push('/dashboard')
          }
        } catch (error) {
          console.error('Error fetching project:', error)
          this.$router.push('/dashboard')
        }
      },
      async fetchTimesheets(userId) {
        try {
          const timesheetsRef = collection(
            db,
            'USERS',
            userId,
            'projects',
            this.projectId,
            'timesheets'
          )
          const timesheetsSnapshot = await getDocs(timesheetsRef)
  
          this.timesheetItems = timesheetsSnapshot.docs.map((doc) => {
            const data = doc.data()
            const monthId = doc.id
            const year = monthId.substring(0, 4)
            const monthNumber = monthId.substring(4, 6)
            const monthName = new Date(
              year,
              parseInt(monthNumber) - 1
            ).toLocaleString('default', { month: 'long' })
            return {
              month: `${monthName} ${year}`,
              timesheetExcelUrl: data.timesheetExcelUrl || null,
              isLoading: false, // Added here
            }
          })
  
          // Sort the items by month in descending order
          this.timesheetItems.sort((a, b) => b.month.localeCompare(a.month))
        } catch (error) {
          console.error('Error fetching timesheets:', error)
        }
      },
      async triggerTimesheetGeneration(item) {
        item.isLoading = true
  
        try {
          const auth = getAuth()
          const user = auth.currentUser
  
          if (!user) {
            this.$router.push('/login')
            item.isLoading = false
            return
          }
  
          const functions = getFunctions()
  
          const generateTimesheet = httpsCallable(functions, 'generateTimesheetOnDemand')
  
          // Parse month and year from the item.month (e.g., "August 2023")
          const [monthName, year] = item.month.split(' ')
          const monthNumber = new Date(`${monthName} 1, ${year}`).getMonth() + 1
  
          const result = await generateTimesheet({
            projectId: this.projectId,
            month: monthNumber,
            year: parseInt(year, 10),
          })
  
          console.log('Timesheet generation successful:', result.data)
          this.successMessage = 'Timesheet generated and sent successfully.'
          this.showSuccessSnackbar = true
  
          await this.fetchTimesheets(user.uid)
        } catch (error) {
          console.error('Error generating timesheet:', error)
  
          if (error.code === 'not-found') {
            this.errorMessage = 'No timesheet data found for the specified month and year.'
          } else if (error.code === 'invalid-argument') {
            this.errorMessage = 'Invalid project ID, month, or year.'
          } else {
            this.errorMessage = 'Error generating timesheet. Please try again later.'
          }
          this.showErrorSnackbar = true
        } finally {
          item.isLoading = false
        }
      },
    },
  }
  </script>
  <style scoped>
  .v-container {
    max-width: 800px;
    margin: 0 auto;
  }
  
  h1 {
    text-align: center;
    margin-bottom: 20px;
  }
  </style>
  