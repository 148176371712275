<template>
    <v-main>
      <v-container>
        <h1>Configure Your Details</h1>
        <v-form ref="form" v-model="valid" @submit.prevent="onSubmit">
          <!-- Your Company Information -->
          <v-card class="mb-6">
            <v-card-title>1. Your Company Information</v-card-title>
            <v-card-text>
              <!-- VAT Number Field and Retrieve Info Button -->
              <v-row align="center">
                <v-col cols="12" sm="8">
                  <v-text-field
                    v-model="vatNumberInput"
                    label="VAT Number"
                    :rules="vatNumberRules"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-btn
                    :loading="loading"
                    color="primary"
                    @click="validateVATNumber"
                    block
                  >
                    Retrieve Info
                  </v-btn>
                </v-col>
              </v-row>
  
              <!-- Company Name Field -->
              <v-text-field
                v-model="companyName"
                label="Company Name"
                :rules="companyNameRules"
                required
              ></v-text-field>
  
              <!-- Street Address Field -->
              <v-text-field
                v-model="streetAddress"
                label="Street Address"
                :rules="streetAddressRules"
                required
              ></v-text-field>
  
              <!-- City Name Field -->
              <v-text-field
                v-model="cityName"
                label="City"
                :rules="cityNameRules"
                required
              ></v-text-field>
  
              <!-- Postal Code Field -->
              <v-text-field
                v-model="postalCode"
                label="Postal Code"
                :rules="postalCodeRules"
                required
              ></v-text-field>
  
              <!-- Company Country Field -->
              <v-select
                v-model="companyCountry"
                :items="countryOptions"
                label="Company Country"
                :rules="companyCountryRules"
                item-title="text"
                item-value="value"
                required
              ></v-select>
            </v-card-text>
          </v-card>
  
          <!-- Your Banking Information -->
          <v-card class="mb-6">
            <v-card-title>2. Your Banking Information</v-card-title>
            <v-card-text>
              <!-- Bank Account Number (IBAN) -->
              <v-text-field
                v-model="bankAccount"
                label="Bank Account"
                :rules="bankAccountRules"
                required
              ></v-text-field>
  
              <!-- SWIFT/BIC Code -->
              <v-text-field
                v-model="swiftCode"
                label="SWIFT/BIC Code (needed for e-invoice)"
                :rules="swiftCodeRules"
                required
              ></v-text-field>
            </v-card-text>
          </v-card>
  
          <!-- Your Contact Information -->
          <v-card class="mb-6">
            <v-card-title>3. Your Contact Information (will be shown on invoice)</v-card-title>
            <v-card-text>
              <!-- First Name Field -->
              <v-text-field
                v-model="firstName"
                label="First Name"
                :rules="firstNameRules"
                required
              ></v-text-field>
  
              <!-- Last Name Field -->
              <v-text-field
                v-model="lastName"
                label="Last Name"
                :rules="lastNameRules"
                required
              ></v-text-field>
  
              <!-- Phone Number Field (Optional) -->
              <v-text-field
                v-model="phoneNumber"
                label="Phone Number (Optional)"
              ></v-text-field>
  
              <!-- Email Field -->
              <v-text-field
                v-model="invoiceEmail"
                label="Email Address"
                :rules="emailRules"
                required
              ></v-text-field>
  
              <!-- Website (Optional) -->
              <v-text-field
                v-model="website"
                label="Website (Optional)"
                :rules="websiteRules"
              ></v-text-field>
            </v-card-text>
          </v-card>
  
          <!-- Your VAT Information -->
          <v-card class="mb-6">
            <v-card-title>4. Your VAT Information</v-card-title>
            <v-card-text>
              <!-- VAT Rate Field -->
              <v-text-field
                v-model="vatRate"
                label="VAT Rate (%)"
                :rules="vatRateRules"
                type="number"
                required
              ></v-text-field>
  
              <!-- Invoice Currency -->
              <v-select
                v-model="invoiceCurrency"
                :items="currencyOptions"
                label="Invoice Currency"
                :rules="invoiceCurrencyRules"
                item-title="text"
                item-value="value"
                required
              ></v-select>
            </v-card-text>
          </v-card>
  
          <!-- Submit Button -->
          <v-btn color="primary" type="submit">Save Details</v-btn>
        </v-form>
  
        <!-- Snackbar for User Feedback -->
        <v-snackbar
          v-model="snackbar.show"
          :color="snackbar.color"
          timeout="3000"
          top
          right
        >
          {{ snackbar.message }}
          <v-btn color="white" text @click="snackbar.show = false">Close</v-btn>
        </v-snackbar>
      </v-container>
    </v-main>
  </template>
  
  <script>
  import { getAuth } from 'firebase/auth'
  import { doc, setDoc, getDoc } from 'firebase/firestore'
  import { db, functions } from '@/firebaseConfig'
  import { httpsCallable } from 'firebase/functions'
  
  export default {
    name: 'ConfigureDetailsPage',
    data() {
      return {
        valid: false,
        loading: false, // For loading animation
        // Company Information
        vatNumberInput: '',
        companyName: '',
        streetAddress: '',
        cityName: '',
        postalCode: '',
        companyCountry: '',
        // Banking Information
        bankAccount: '',
        swiftCode: '',
        // Contact Information
        firstName: '',
        lastName: '',
        phoneNumber: '',
        invoiceEmail: '',
        website: '',
        // VAT Information
        vatRate: null,
        invoiceCurrency: '',
        // Snackbar for user feedback
        snackbar: {
          show: false,
          message: '',
          color: 'success',
        },
        // Validation Rules
        vatNumberRules: [(v) => !!v || 'VAT number is required'],
        companyNameRules: [(v) => !!v || 'Company name is required'],
        streetAddressRules: [(v) => !!v || 'Street address is required'],
        cityNameRules: [(v) => !!v || 'City name is required'],
        postalCodeRules: [(v) => !!v || 'Postal code is required'],
        companyCountryRules: [(v) => !!v || 'Please select your company country'],
        firstNameRules: [(v) => !!v || 'First name is required'],
        lastNameRules: [(v) => !!v || 'Last name is required'],
        emailRules: [
          (v) => !!v || 'Email is required',
          (v) => /.+@.+\..+/.test(v) || 'Email must be valid',
        ],
        websiteRules: [
          (v) =>
            !v ||
            /^(https?:\/\/)?([\w-]+\.)+[\w-]{2,4}(\/[\w-]*)*\/?$/.test(v) ||
            'Invalid website URL',
        ],
        vatRateRules: [
          (v) => !!v || 'VAT rate is required',
          (v) => (v >= 0 && v <= 100) || 'VAT rate must be a number between 0 and 100',
        ],
        invoiceCurrencyRules: [(v) => !!v || 'Invoice currency is required'],
        // Country Options
        countryOptions: [
          { text: 'Argentina', value: 'AR' },
          { text: 'Australia', value: 'AU' },
          { text: 'Austria', value: 'AT' },
          { text: 'Belgium', value: 'BE' },
          { text: 'Brazil', value: 'BR' },
          { text: 'Canada', value: 'CA' },
          { text: 'Chile', value: 'CL' },
          { text: 'China', value: 'CN' },
          { text: 'Colombia', value: 'CO' },
          { text: 'Bulgaria', value: 'BG' },
          { text: 'Croatia', value: 'HR' },
          { text: 'Cyprus', value: 'CY' },
          { text: 'Czech Republic', value: 'CZ' },
          { text: 'Denmark', value: 'DK' },
          { text: 'Egypt', value: 'EG' },
          { text: 'Estonia', value: 'EE' },
          { text: 'Finland', value: 'FI' },
          { text: 'France', value: 'FR' },
          { text: 'Germany', value: 'DE' },
          { text: 'Greece', value: 'GR' },
          { text: 'Hong Kong', value: 'HK' },
          { text: 'Hungary', value: 'HU' },
          { text: 'Iceland', value: 'IS' },
          { text: 'India', value: 'IN' },
          { text: 'Indonesia', value: 'ID' },
          { text: 'Ireland', value: 'IE' },
          { text: 'Israel', value: 'IL' },
          { text: 'Italy', value: 'IT' },
          { text: 'Japan', value: 'JP' },
          { text: 'Latvia', value: 'LV' },
          { text: 'Lithuania', value: 'LT' },
          { text: 'Luxembourg', value: 'LU' },
          { text: 'Malaysia', value: 'MY' },
          { text: 'Mexico', value: 'MX' },
          { text: 'Malta', value: 'MT' },
          { text: 'Netherlands', value: 'NL' },
          { text: 'New Zealand', value: 'NZ' },
          { text: 'Nigeria', value: 'NG' },
          { text: 'Norway', value: 'NO' },
          { text: 'Peru', value: 'PE' },
          { text: 'Philippines', value: 'PH' },
          { text: 'Poland', value: 'PL' },
          { text: 'Portugal', value: 'PT' },
          { text: 'Russia', value: 'RU' },
          { text: 'Saudi Arabia', value: 'SA' },
          { text: 'Singapore', value: 'SG' },
          { text: 'Romania', value: 'RO' },
          { text: 'Slovakia', value: 'SK' },
          { text: 'Slovenia', value: 'SI' },
          { text: 'South Africa', value: 'ZA' },
          { text: 'South Korea', value: 'KR' },
          { text: 'Spain', value: 'ES' },
          { text: 'Sweden', value: 'SE' },
          { text: 'Switzerland', value: 'CH' },
          { text: 'Taiwan', value: 'TW' },
          { text: 'Thailand', value: 'TH' },
          { text: 'Turkey', value: 'TR' },
          { text: 'United Arab Emirates', value: 'AE' },
          { text: 'United Kingdom', value: 'GB' },
          { text: 'United States', value: 'US' },
          { text: 'Venezuela', value: 'VE' },
          { text: 'Vietnam', value: 'VN' },
        ],
        // Currency Options
        currencyOptions: [
          { text: 'Argentine Peso (ARS)', value: 'ARS' },
          { text: 'Australian Dollar (A$)', value: 'AUD' },
          { text: 'Euro (€)', value: 'EUR' },
          { text: 'Brazilian Real (R$)', value: 'BRL' },
          { text: 'Canadian Dollar (C$)', value: 'CAD' },
          { text: 'Chilean Peso (CLP)', value: 'CLP' },
          { text: 'Chinese Yuan (¥)', value: 'CNY' },
          { text: 'Colombian Peso (COP)', value: 'COP' },
          { text: 'Bulgarian Lev (BGN)', value: 'BGN' },
          { text: 'Czech Koruna (CZK)', value: 'CZK' },
          { text: 'Danish Krone (DKK)', value: 'DKK' },
          { text: 'Egyptian Pound (EGP)', value: 'EGP' },
          { text: 'Hong Kong Dollar (HKD)', value: 'HKD' },
          { text: 'Hungarian Forint (HUF)', value: 'HUF' },
          { text: 'Icelandic Króna (ISK)', value: 'ISK' },
          { text: 'Indian Rupee (₹)', value: 'INR' },
          { text: 'Indonesian Rupiah (IDR)', value: 'IDR' },
          { text: 'Israeli New Shekel (₪)', value: 'ILS' },
          { text: 'Japanese Yen (¥)', value: 'JPY' },
          { text: 'Malaysian Ringgit (MYR)', value: 'MYR' },
          { text: 'Mexican Peso (MXN)', value: 'MXN' },
          { text: 'New Zealand Dollar (NZD)', value: 'NZD' },
          { text: 'Nigerian Naira (NGN)', value: 'NGN' },
          { text: 'Norwegian Krone (NOK)', value: 'NOK' },
          { text: 'Peruvian Sol (PEN)', value: 'PEN' },
          { text: 'Philippine Peso (PHP)', value: 'PHP' },
          { text: 'Polish Złoty (PLN)', value: 'PLN' },
          { text: 'Russian Ruble (₽)', value: 'RUB' },
          { text: 'Saudi Riyal (SAR)', value: 'SAR' },
          { text: 'Singapore Dollar (SGD)', value: 'SGD' },
          { text: 'Romanian Leu (RON)', value: 'RON' },
          { text: 'South African Rand (ZAR)', value: 'ZAR' },
          { text: 'South Korean Won (₩)', value: 'KRW' },
          { text: 'Swedish Krona (SEK)', value: 'SEK' },
          { text: 'Swiss Franc (CHF)', value: 'CHF' },
          { text: 'Taiwan Dollar (TWD)', value: 'TWD' },
          { text: 'Thai Baht (THB)', value: 'THB' },
          { text: 'Turkish Lira (TRY)', value: 'TRY' },
          { text: 'UAE Dirham (AED)', value: 'AED' },
          { text: 'British Pound (£)', value: 'GBP' },
          { text: 'US Dollar ($)', value: 'USD' },
          { text: 'Venezuelan Bolívar (VES)', value: 'VES' },
          { text: 'Vietnamese Dong (VND)', value: 'VND' },
        ],
      }
    },
    async mounted() {
      try {
        const auth = getAuth()
        const user = auth.currentUser
        if (!user) {
          throw new Error('User is not authenticated')
        }
  
        // Split the user's display name into first and last name if available
        if (user.displayName) {
          const names = user.displayName.split(' ')
          this.firstName = names[0] || ''
          this.lastName = names.slice(1).join(' ') || ''
        }
        this.invoiceEmail = user.email
  
        // Fetch existing user data from Firestore
        const docRef = doc(db, 'USERS', user.uid)
        const docSnap = await getDoc(docRef)
        if (docSnap.exists()) {
          const userData = docSnap.data()
          // Populate the form fields with existing data
          this.vatNumberInput = userData.vatNumber || ''
          this.companyName = userData.companyName || ''
          this.streetAddress = userData.streetAddress || ''
          this.cityName = userData.cityName || ''
          this.postalCode = userData.postalCode || ''
          this.companyCountry = userData.companyCountry || ''
          this.bankAccount = userData.bankAccount || ''
          this.swiftCode = userData.swiftCode || ''
          this.phoneNumber = userData.phoneNumber || ''
          this.website = userData.website || ''
          this.vatRate = userData.vatRate || null
          this.invoiceCurrency = userData.invoiceCurrency || ''
          // First and last name may be stored in Firestore
          this.firstName = userData.firstName || this.firstName
          this.lastName = userData.lastName || this.lastName
        }
      } catch (error) {
        console.error('Error fetching user data:', error)
        this.snackbar.message = 'Failed to load user data.'
        this.snackbar.color = 'error'
        this.snackbar.show = true
      }
    },
    methods: {
      async validateVATNumber() {
        if (!this.vatNumberInput) {
          this.snackbar.message = 'Please enter a VAT number.'
          this.snackbar.color = 'error'
          this.snackbar.show = true
          return
        }
        try {
          this.loading = true // Start loading animation
  
          // Format VAT number by removing non-alphanumeric characters
          const vatNumber = this.vatNumberInput.replace(/[^a-zA-Z0-9]/g, '').toUpperCase()
  
          const validateVAT = httpsCallable(functions, 'validateVAT')
          const result = await validateVAT({ vatNumber })
          console.log('validateVAT result:', result)
  
          // Check if the VAT number is registered
          if (
            result.data.registration_info &&
            result.data.registration_info.is_registered
          ) {
            // Pre-fill company details based on API response
            this.companyName =
              result.data.registration_info.name || this.companyName
  
            // Split the address into components
            if (result.data.registration_info.address) {
              const address = result.data.registration_info.address
              this.parseAddress(address)
            }
  
            this.companyCountry =
              result.data.country_code || this.companyCountry
  
            this.snackbar.message =
              'VAT number is valid. Company details have been pre-filled.'
            this.snackbar.color = 'success'
            this.snackbar.show = true
          } else {
            this.snackbar.message = 'Invalid VAT number.'
            this.snackbar.color = 'error'
            this.snackbar.show = true
          }
        } catch (error) {
          console.error('VAT validation error:', error)
          this.snackbar.message = 'Failed to validate VAT number.'
          this.snackbar.color = 'error'
          this.snackbar.show = true
        } finally {
          this.loading = false // Stop loading animation
        }
      },
      parseAddress(address) {
        // Assuming the address is in the format "Street Address\nPostal Code City"
        const lines = address.split('\n')
        if (lines.length >= 2) {
          this.streetAddress = lines[0]
          const cityLine = lines[1]
          const match = cityLine.match(/^(\d{4,6})\s+(.+)/)
          if (match) {
            this.postalCode = match[1]
            this.cityName = match[2]
          } else {
            this.cityName = cityLine
          }
        } else {
          this.streetAddress = address
        }
      },
      async onSubmit() {
        if (this.$refs.form.validate()) {
          try {
            const auth = getAuth()
            const user = auth.currentUser
  
            if (!user) {
              throw new Error('User is not authenticated')
            }
            const vatNumberSave = this.vatNumberInput.replace(/[^a-zA-Z0-9]/g, '').toUpperCase()
            // Prepare data to save
            const userData = {
              vatNumber: vatNumberSave,
              companyName: this.companyName,
              streetAddress: this.streetAddress,
              cityName: this.cityName,
              postalCode: this.postalCode,
              companyCountry: this.companyCountry,
              bankAccount: this.bankAccount,
              swiftCode: this.swiftCode,
              phoneNumber: this.phoneNumber,
              invoiceEmail: this.invoiceEmail,
              website: this.website,
              vatRate: this.vatRate,
              invoiceCurrency: this.invoiceCurrency,
              firstName: this.firstName,
              lastName: this.lastName,
            }
  
            // Save the details to Firestore
            await setDoc(doc(db, 'USERS', user.uid), userData, { merge: true })
  
            // Provide user feedback using Vuetify's v-snackbar
            this.snackbar.message = 'Details saved successfully!'
            this.snackbar.color = 'success'
            this.snackbar.show = true
  
            // Optionally, redirect to another page
            // this.$router.push('/dashboard')
          } catch (error) {
            console.error('Error saving details:', error)
            this.snackbar.message = 'Failed to save details.'
            this.snackbar.color = 'error'
            this.snackbar.show = true
          }
        }
      },
    },
  }
  </script>
  
  <style scoped>
  .v-container {
    max-width: 800px;
    margin: 0 auto;
  }
  
  h1 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .v-card-title {
    font-weight: bold;
  }
  
  .v-card {
    border: 1px solid #ccc;
    border-radius: 8px;
  }
  </style>
  